import React, { useContext, useMemo, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoFemaleSharp, IoMale, IoMaleFemale } from "react-icons/io5";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import GlobalSearchCell from "./GlobalSearchCell";
import GlobalSearchHeaderCell from "./GlobalSearchHeaderCell";
import { SearchResultsTableProps } from "./types";

const formatSearchTerm = (term: string, label: string) =>
	term ? `${label}: ${term}` : "";

const GlobalSearchResultsTable = ({
	results,
	isLoading,
	currentPage,
	searchTerm,
	setCurrentPage,
	selectedTab
}: SearchResultsTableProps) => {
	const { lang } = useContext(AppContext);
	const [itemsPerPage, setItemsPerPage] = useState<number>(30);

	const displayedResults = useMemo(() => {
		let filteredResults = [];

		if (selectedTab === 0 && results.inscriptions) {
			filteredResults = results.inscriptions;
		}

		if (selectedTab === 1 && results.personnes) {
			filteredResults = results.personnes;
		}

		if (selectedTab === 2 && results.commandes) {
			filteredResults = results.commandes;
		}

		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;

		return {
			results: filteredResults.slice(
				(currentPage - 1) * itemsPerPage,
				endIndex
			),
			pages: Math.ceil(filteredResults.length / itemsPerPage)
		};
	}, [results, currentPage, itemsPerPage, selectedTab]);

	const handlePreviousPage = () =>
		setCurrentPage((prev) => Math.max(1, prev - 1));

	const handleNextPage = () =>
		setCurrentPage((prev) => Math.min(displayedResults.pages, prev + 1));

	const displaySearchTerm = [
		formatSearchTerm(searchTerm.firstname, "Prénom"),
		formatSearchTerm(searchTerm.lastname, "Nom"),
		formatSearchTerm(searchTerm.email, "Email")
	]
		.filter(Boolean)
		.join(" / ");

	const tableHeaderCellsLabel = [
		trad[lang].name,
		trad[lang].firstname,
		trad[lang].birthdate,
		trad[lang].gender,
		trad[lang].email,
		trad[lang].status,
		trad[lang].account_type
	];

	const tableHeaderCellsLabelSubscription = [
		trad[lang].registration,
		trad[lang].subscriptionType,
		trad[lang].event,
		trad[lang].run
	];

	const tableHeaderCellsLabelCommandAndSub = [
		trad[lang].ttc_price,
		trad[lang].command_date,
		"ID Commande"
	];

	return (
		<div className="mt-6 mb-[38.5px]">
			{isLoading ? (
				<div className="flex animate-pulse flex-row items-center justify-center gap-4 text-xl">
					<h2 className="font-semibold text-gray-700">Chargement...</h2>
					<div className="animate-spin">
						<AiOutlineLoading3Quarters size={20} />
					</div>
				</div>
			) : (
				<div className="flex items-center space-x-2">
					<h2 className="text-lg font-semibold text-gray-800">
						{`${trad[lang].search_results} :`}
					</h2>

					<span className="text-lg font-semibold text-gray-400">
						{displaySearchTerm}
					</span>
				</div>
			)}

			<div className="mt-4 overflow-x-auto">
				<table className="min-w-full table-auto border-collapse border border-gray-300">
					<thead>
						<tr className="bg-gray-200 text-left text-sm font-medium text-gray-600">
							<th className="border border-gray-300 px-4 py-2">ID Personne</th>

							{selectedTab === 0 && (
								<>
									{tableHeaderCellsLabelSubscription.map((label, index) => (
										<GlobalSearchHeaderCell key={index} children={label} />
									))}
									{tableHeaderCellsLabelCommandAndSub.map((label, index) => (
										<GlobalSearchHeaderCell key={index} children={label} />
									))}
									<GlobalSearchHeaderCell children="ID Inscription" />
								</>
							)}

							{selectedTab === 1 &&
								tableHeaderCellsLabel.map((label, index) => (
									<GlobalSearchHeaderCell key={index} children={label} />
								))}

							{selectedTab === 2 && (
								<>
									<GlobalSearchHeaderCell children={trad[lang].payer} />
									{tableHeaderCellsLabelCommandAndSub.map((label, index) => (
										<GlobalSearchHeaderCell key={index} children={label} />
									))}
									<GlobalSearchHeaderCell children="ID Facture" />
								</>
							)}
						</tr>
					</thead>

					<tbody className="text-xs">
						{displayedResults.results.map((result: any) => (
							<React.Fragment key={result.id}>
								{selectedTab === 1 && (
									<tr
										key={result.id}
										className={`${
											result.sex === 1
												? "text-primary"
												: result.sex === 2
												? "text-pink-500"
												: "text-emerald-400"
										} font-semibold odd:bg-white even:bg-gray-100`}
									>
										<GlobalSearchCell value={result.id} />
										<GlobalSearchCell value={result.lastname} />
										<GlobalSearchCell value={result.firstname} />
										<GlobalSearchCell
											value={new Date(result.birthdate).toLocaleDateString()}
										/>
										<GlobalSearchCell
											value={
												result.sex === 1 ? (
													<IoMale size={20} color="primary" />
												) : result.sex === 2 ? (
													<IoFemaleSharp size={20} color="rgb(222, 49, 99)" />
												) : (
													<IoMaleFemale size={20} color="text-emerald-400" />
												)
											}
										/>
										<GlobalSearchCell value={result.email} />
										<GlobalSearchCell
											value={result.status === 1 ? "Normale" : "Fantôme"}
										/>
										<GlobalSearchCell
											value={
												result.roles ? JSON.parse(result.roles)?.join(", ") : ""
											}
										/>
									</tr>
								)}

								{selectedTab === 2 && (
									<tr
										key={result.IDCommande}
										className={`${
											result.sex === 1
												? "text-primary"
												: result.sex === 2
												? "text-pink-500"
												: "text-emerald-400"
										} font-semibold odd:bg-white even:bg-gray-100`}
									>
										<GlobalSearchCell value={result.IDPersonne} />
										<GlobalSearchCell
											value={result.firstname + " " + result.lastname}
										/>
										<GlobalSearchCell
											value={parseFloat(result?.TotalTTC).toFixed(2) + " €"}
											color="text-gray-700"
										/>
										<GlobalSearchCell
											value={new Date(result.CommandeLe).toLocaleString()}
											color="text-gray-700"
										/>
										<GlobalSearchCell
											value={result.IDCommande}
											color="text-gray-700"
										/>
										<GlobalSearchCell
											value={result.IDFacture}
											color="text-gray-700"
										/>
									</tr>
								)}

								{selectedTab === 0 && (
									<tr
										key={result.IDInscription}
										className={`${
											result.sex === 1
												? "text-primary"
												: result.sex === 2
												? "text-pink-500"
												: "text-yellow-300"
										} font-semibold odd:bg-white even:bg-gray-100`}
									>
										<GlobalSearchCell value={result.IDPersonne} />
										<GlobalSearchCell
											value={result.lastname + " " + result.firstname}
										/>
										<GlobalSearchCell
											value={
												result.ide_type_inscription == 1
													? "En ligne"
													: result.ide_type_inscription == 11
													? "Manuelle"
													: "Import"
											}
										/>

										<GlobalSearchCell
											value={result.calendrier_parent}
											color="text-gray-700"
										/>
										<GlobalSearchCell
											value={result.calendrier_fils}
											color="text-gray-700"
										/>

										<GlobalSearchCell
											value={
												parseFloat(result.PrixProduitTTC).toFixed(2) + " €"
											}
											color="text-gray-700"
										/>
										<GlobalSearchCell
											value={new Date(result.CommandeLe).toLocaleString()}
											color="text-gray-700"
										/>
										<GlobalSearchCell
											value={result.IDCommande}
											color="text-gray-700"
										/>
										<GlobalSearchCell
											value={result.IDInscription}
											color="text-gray-700"
										/>
									</tr>
								)}
							</React.Fragment>
						))}

						{displayedResults.results.length === 0 && !isLoading ? (
							<tr>
								<td
									colSpan={8}
									className="border border-gray-300 px-4 py-2 text-center text-gray-500"
								>
									Aucun résultat trouvé.
								</td>
							</tr>
						) : (
							""
						)}
					</tbody>
				</table>
			</div>

			{/* Pagination Controls */}
			{displayedResults.pages > 1 && (
				<div className="growitems-center fixed bottom-0 flex w-full justify-center gap-4 border-t bg-white p-2">
					<div className="flex w-full items-center justify-center gap-4">
						<button
							onClick={handlePreviousPage}
							disabled={currentPage === 1}
							className={`rounded-md px-4 py-2 text-sm font-medium ${
								currentPage === 1
									? "cursor-not-allowed bg-gray-300 text-gray-500"
									: "bg-blue-600 text-white hover:bg-blue-700"
							}`}
						>
							Précédent
						</button>
						<span className="text-sm font-medium text-gray-700">
							Page {currentPage} sur {displayedResults.pages}
						</span>
						<button
							onClick={handleNextPage}
							disabled={currentPage === displayedResults.pages}
							className={`rounded-md px-4 py-2 text-sm font-medium ${
								currentPage === displayedResults.pages
									? "cursor-not-allowed bg-gray-300 text-gray-500"
									: "bg-blue-600 text-white hover:bg-blue-700"
							}`}
						>
							Suivant
						</button>
						<select
							className=" block rounded border border-gray-300 text-center text-sm font-medium text-gray-600"
							value={itemsPerPage}
							onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
						>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="30">30</option>
							<option value="40">40</option>
							<option value="50">50</option>
						</select>
					</div>
				</div>
			)}
		</div>
	);
};

export default GlobalSearchResultsTable;

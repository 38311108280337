import { useContext } from "react";
import { useQuery } from "react-query";
import { getSubscriptionOptions } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import { LiveContext } from "../../contexts/LiveContext";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";
import { getCountryNameByWindevId } from "../../utils/CountriesList";
import { calculateAge } from "../../utils/DateFormater";

const RegistrationsDetails = ({
	subscription,
	openDocument,
	status,
	docsAsked
}: {
	subscription: any;
	openDocument: any;
	status: any;
	docsAsked: any;
}) => {
	const { lang } = useContext(AppContext);
	const { userIsAdmin } = useContext(UserContext);
	const { isFFAEvent } = useContext(LiveContext);

	const { data } = useQuery({
		queryKey: ["subscription_option", subscription.id],
		queryFn: () => getSubscriptionOptions(subscription.id),
		refetchOnWindowFocus: false
	});
	const getOptionLabel = function (opt: any, index: number) {
		let res: string | null = null;

		if (opt.typeName === "donation") {
			res = `${opt.designation} : ${
				opt.PrixTTCUnitaire !== undefined
					? opt.PrixTTCUnitaire
					: opt.Quantite || 0
			}€`;
		} else if (opt.Quantite) {
			res = `${opt.designation} : ${opt.Quantite}`;
		} else {
			// Search in products list
			if (opt.products && opt.products.length) {
				// Find the one with Quantite > 0
				let product = opt.products.find((p: any) => p.Quantite > 0);
				if (product) {
					res = `${opt.designation} : ${product.designation}`;
				}
			}
		}

		if (!res) return <></>;

		return (
			<p className="w-1/2 text-sm md:w-1/4" key={index}>
				{res}
			</p>
		);
	};

	// const askedDocs = // subscription.isFFA
	// 	// 	? ["autorisation"]
	// 	/*:*/ ["licence", "certificat", "autorisation"].filter(
	// 		(item) => (docsAsked as any)[item]
	// 	);

	return (
		<tr>
			<td colSpan={7} className="py-3 px-5">
				<h3 className="font-bold">{trad[lang].personnal_informations}</h3>

				<div className="flex flex-row flex-wrap">
					<p className="w-1/2 text-sm sm:w-1/4">
						{`${trad[lang].birthdate} : ${
							subscription.person.birthdate
								? new Date(subscription.person.birthdate)
										.toLocaleString("fr-FR", { timeZone: "UTC" })
										.slice(0, 10)
								: ""
						}`}
					</p>
					<p className="w-1/2 text-sm sm:w-1/4">
						{`${trad[lang].age} ${
							subscription.person.birthdate
								? calculateAge(subscription.person.birthdate)
								: ""
						}`}
					</p>
					<p className="w-1/2 text-sm sm:w-1/4">
						{`${trad[lang].country} : ${getCountryNameByWindevId(
							subscription.person.country,
							lang
						)}`}
					</p>
					<p className="w-1/2 text-sm sm:w-1/4">
						{`${trad[lang].nationality} : ${
							getCountryNameByWindevId(subscription.person.nationality, lang) ||
							subscription.person.nationality ||
							""
						}`}
					</p>
					<p className="w-1/2 text-sm sm:w-1/4">
						{`${trad[lang].phone} : ${subscription.person.phone || ""}`}
					</p>
					<p className="w-1/2 text-sm sm:w-1/4">
						{`${trad[lang].emergency_phone} : ${
							subscription.person.emergency_phone || ""
						}`}
					</p>
					<p className="w-1/2 text-sm sm:w-1/4">
						{`${trad[lang].mail} : ${subscription.person.email || ""}`}
					</p>
					<p className="w-1/2 text-sm sm:w-1/4">
						{`${trad[lang].city} : ${subscription.person.city || ""}`}
					</p>
					<p className="w-1/2 text-sm sm:w-1/4">
						{`${trad[lang].postal_code} : ${
							subscription.person.postal_code || ""
						}`}
					</p>
				</div>

				{/* {askedDocs.length > 0 && (
					<>
						<h3 className="mt-2 font-bold">{trad[lang].documents}</h3>

						<div className="flex flex-col flex-wrap sm:flex-row">
							{askedDocs.map((doc_key) => (
								<div
									className="flex w-full flex-row gap-2 text-sm sm:items-center md:w-1/2 lg:w-1/3"
									key={doc_key}
								>
									<p className="w-fit">
										{trad[lang][doc_key as IDocumentKey]} :
									</p>

									<p
										className={`cursor-pointer rounded-xl py-1 px-4 transition-colors ${
											subscription[doc_key]?.id
												? status[subscription[doc_key].statut || 0].color
												: "border border-gray-300 bg-gray-100 text-gray-800 hover:bg-gray-300 hover:text-gray-900 hover:shadow-sm"
										}`}
										onClick={(e) =>
											openDocument(e, subscription[doc_key], doc_key)
										}
									>
										{subscription[doc_key]?.id
											? status[subscription[doc_key].statut || 0].text
											: trad[lang].no_document}
									</p>
								</div>
							))}
						</div>
					</>
				)} */}

				<h3 className="mt-2 font-bold">{trad[lang].run_informations}</h3>

				<div className="flex flex-row flex-wrap">
					<p className="w-1/2 text-sm md:w-1/4">
						{`${trad[lang].bib_info} : ${subscription.bib || ""}`}
					</p>
					<p className="w-1/2 text-sm md:w-1/4">
						{`${trad[lang].run} : ${subscription.calendrier.nom || ""}`}
					</p>
					<p className="w-1/2 text-sm md:w-1/4">
						{`${trad[lang].category} : ${
							subscription.categories?.length > 0
								? subscription.categories[0].name
								: ""
						}`}
					</p>
					<p className="w-1/2 text-sm md:w-1/4">
						{`${trad[lang].club} : ${
							JSON.parse(subscription.observations)?.infoPersonne?.club || ""
						}`}
					</p>
					<p className="w-1/2 text-sm md:w-1/4">
						{`${trad[lang].team} : ${subscription?.equipe?.nom || ""}`}
					</p>
					<p className="w-1/2 text-sm md:w-1/4">
						{`${trad[lang].licence} : ${
							JSON.parse(subscription.observations)?.infoPersonne?.numLicence ||
							""
						}`}
					</p>
					{isFFAEvent && (
						<p className="w-1/2 text-sm md:w-1/4">
							{`PPS : ${
								JSON.parse(subscription.observations)?.infoPersonne?.pps || ""
							}`}
						</p>
					)}
				</div>

				{subscription.inscription_choix?.length > 0 && (
					<>
						<h3 className="mt-2 font-bold">
							{trad[lang].additional_information}
						</h3>
						<div className="flex flex-row flex-wrap">
							{subscription.inscription_choix?.map((choix: any) => (
								<p className="w-1/2 text-sm md:w-1/4" key={choix.id}>
									{choix.libelleList} : {choix.libelle}
								</p>
							))}
						</div>
					</>
				)}

				{userIsAdmin && (
					<>
						<h3 className="mt-2 font-bold">Database DATA (Admin only)</h3>

						<div className="flex flex-row flex-wrap">
							<p className="w-1/2 text-sm md:w-1/4">
								{`calendar.id ${subscription.calendrier?.id}`}
							</p>
							<p className="w-1/2 text-sm md:w-1/4">
								{`ide_personne ${subscription.ide_personne}`}
							</p>
							<p className="w-1/2 text-sm md:w-1/4">{`inscription.id ${subscription.id}`}</p>
							<p className="w-1/2 text-sm md:w-1/4">
								{`ide_type_inscription ${subscription.ide_type_inscription}`}
							</p>
						</div>
					</>
				)}

				{data?.length > 0 && (
					<>
						<h3 className="mt-2 font-bold">{trad[lang].sub_options}</h3>

						<div className="flex flex-row flex-wrap">
							{data.map((item: any, index: number) =>
								getOptionLabel(item, index)
							)}
						</div>
					</>
				)}
			</td>
		</tr>
	);
};

export default RegistrationsDetails;

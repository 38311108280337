import trad from "../lang/traduction";

export const PatchNotes = (lang: "fr" | "en") => {
	const traduction = trad[lang];

	return [
		{
			version: "2.15.4",
			features: [
				traduction.patchPDFExportRaces,
				traduction.patchRegistrations,
				traduction.patchUX
			]
		},
		{
			version: "2.10.3",
			features: [traduction.patchDocumentPreview]
		},
		{
			version: "2.10.2",
			features: [traduction.patchAddResults]
		},
		{
			version: "2.10.1",
			features: [traduction.patchEditOption]
		},
		{
			version: "2.10.0",
			features: [traduction.patchAddRibSelector]
		},
		{
			version: "2.9.9",
			features: [traduction.patchUpdateAdminStatistics]
		},
		{
			version: "2.9.8",
			features: [traduction.patchSwitchSubscriptionStatus]
		},
		{
			version: "2.9.7",
			features: [traduction.patchRegistrations]
		},
		{
			version: "2.9.6",
			features: [
				traduction.patchReasignBibsAndKeepThem,
				traduction.patchAddRegistration,
				traduction.patchGenderX
			]
		},
		{
			version: "2.9.5",
			features: [traduction.patchAddPdfExport]
		},
		{
			version: "2.9.2",
			features: [traduction.patchForgetPassword]
		},
		{
			version: "2.9.1",
			features: [traduction.patchPictoDocs]
		},
		{
			version: "2.9.0",
			features: [traduction.patchEventImages]
		},
		{
			version: "2.8.10",
			features: [traduction.patchEventImages]
		},
		{
			version: "2.8.9",
			features: [traduction.patchTraduction]
		},
		{
			version: "2.8.8",
			features: [traduction.patchRedirectionToValidate]
		},
		{
			version: "2.8.7",
			features: [traduction.patchResponsive, traduction.patchHoursPrices]
		},
		{
			version: "2.8.6",
			features: [traduction.patchUpdateSendOneMail, traduction.patchMailHistory]
		},
		{ version: "2.8.5", features: [traduction.patchChangeDashboardLinks] },
		{ version: "2.8.4", features: [traduction.patchFixInvoiceErrorMessage] },
		{
			version: "2.8.3",
			features: [traduction.patchAddNotes]
		},
		{
			version: "2.8.2",
			features: [
				traduction.patchAddJetcodes,
				traduction.patchAddRoles,
				traduction.patchCorrectDocuments
			]
		}
	];
};

import dayjs from "dayjs";
import "dayjs/locale/fr";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
	unstable_useBlocker,
	useBeforeUnload,
	useParams
} from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import {
	deleteLiveConfigPoint,
	deleteSegment,
	getLiveConfigPoints,
	getLiveConfigSegments,
	postLiveConfigPoint,
	updateLiveConfigCalendar,
	updateSegment
} from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { IFormKeys } from "../../types/LiveForm";
import { formatLikeISO } from "../../utils/DateFormater";
import Toast from "../../utils/Toasts";
import { useQuery } from "react-query";
import { RiArrowGoBackFill } from "react-icons/ri";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("fr");

const LiveConfigurationCalendar = ({
	liveConfig,
	runLiveConfig,
	refetchLiveConfig,
	refetchPoints,
	refetchSegments,
	segment,
	point
}: {
	liveConfig: any;
	runLiveConfig: any;
	refetchLiveConfig: any;
	refetchPoints: any;
	refetchSegments: any;
	segment: any;
	point: any;
}) => {
	const { lang, unsavedChangesRef } = useContext(AppContext);
	const { slug, idcalendrier } = useParams();
	const [saveNeeded, setSaveNeeded] = useState(false);
	const [isToggled, setIsToggled] = useState(false);
	const [runForm, setRunForm] = useState({
		type: { value: liveConfig.type, error: false },
		distance: { value: liveConfig.distance, error: false },
		laps: { value: liveConfig.laps, error: false },
		date: {
			value: formatLikeISO(
				dayjs(liveConfig.date).tz(liveConfig.timezone, true).toDate()
			),
			error: false
		}
	});
	const [loading, setLoading] = useState(false);

	const toggle = () => setIsToggled(!isToggled);
	const debouncedRunForm = useDebounce(runForm, 1000);

	// Unsaved changes ? (Display prompt if true)
	const isPromptConsumedRef = useRef(false);

	unstable_useBlocker((args) => {
		if (unsavedChangesRef.current && isPromptConsumedRef.current === false) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			return !userDiscarded;
		}
		return false;
	});

	useBeforeUnload(
		useCallback(
			(e) => {
				if (unsavedChangesRef.current) {
					e.preventDefault();
					return (e.returnValue = "");
				}
			},
			[unsavedChangesRef.current]
		)
	);

	const firstStepValidation: {
		key: IFormKeys;
		dependency?: IFormKeys;
		hasError: (value: any, dependecy: any) => boolean;
	}[] = [
		{ key: "type", hasError: (value: any) => !value },
		{
			key: "distance",
			dependency: "type",
			hasError: (value: any, dependency: any) =>
				dependency != 3 && dependency != 4 && value <= 0
		},
		{
			key: "laps",
			dependency: "type",
			hasError: (value: any, dependency: any) =>
				(dependency == 2 || dependency == 4) && !value
		}
	];

	const setFormValue = async function (key: keyof typeof runForm, value: any) {
		setSaveNeeded(true);
		setRunForm((old) => ({ ...old, [key]: { value, error: false } }));
		unsavedChangesRef.current = true;
	};

	useEffect(() => {
		if (
			debouncedRunForm.type.value == liveConfig.type &&
			debouncedRunForm.distance.value == liveConfig.distance &&
			debouncedRunForm.laps.value == liveConfig.laps &&
			debouncedRunForm.date.value ==
				formatLikeISO(
					dayjs(liveConfig.date).tz(liveConfig.timezone, true).toDate()
				)
		)
			return;

		ValidateLiveConfigCalendar();
	}, [debouncedRunForm]);

	const setFormError = (key: keyof typeof runForm, value: boolean) =>
		setRunForm((old) => {
			return { ...old, [key]: { ...old[key], error: value } };
		});

	const ValidateLiveConfigCalendar = async () => {
		try {
			if (!slug || !idcalendrier) return;
			let error = false;

			for (const item of firstStepValidation) {
				const hasError = item.hasError(
					runForm[item.key].value,
					item.dependency ? runForm[item.dependency].value : undefined
				);

				if (hasError) {
					error = true;
					if (runForm[item.key].error !== true) setFormError(item.key, true);
				}
			}

			if (error) {
				throw new Error("Données du formulaire invalides");
			}

			setLoading(true);

			try {
				if (saveNeeded)
					await updateLiveConfigCalendar(slug, idcalendrier, {
						type: runForm.type.value,
						distance: runForm.distance.value,
						laps: runForm.laps.value,
						date: dayjs(runForm.date.value)
							.tz(liveConfig.timezone, true)
							.toISOString()
					});

				Toast.success(trad[lang].saveConfigRunSuccess);
			} catch (error) {
				console.error(error);
				Toast.error(trad[lang].saveConfigRunError);
			}

			unsavedChangesRef.current = false;
			setSaveNeeded(false);

			refetchLiveConfig();
			refetchPoints();
			refetchSegments();
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormValue("date", e.target.value);
	};

	const msToTime = (milliseconds: number) => {
		let totalSeconds = Math.floor(milliseconds / 1000);
		let hours: number = Math.floor(totalSeconds / 3600);
		let minutes: number = Math.floor((totalSeconds % 3600) / 60);
		let seconds: number = totalSeconds % 60;

		const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
		const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

		return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	};

	const importRaceConfig = async (index: number) => {
		try {
			if (!slug || !idcalendrier) return;
			setLoading(true);
			setFormValue(
				"type",
				parseInt(
					runLiveConfig?.calendrier_child[index]?.configuration_calendrier?.type
				)
			);
			setFormValue(
				"distance",
				parseInt(
					runLiveConfig?.calendrier_child[index]?.configuration_calendrier
						?.distance
				)
			);
			setFormValue(
				"laps",
				parseInt(
					runLiveConfig?.calendrier_child[index]?.configuration_calendrier
						?.nombreTour
				)
			);
			setFormValue(
				"date",
				formatLikeISO(
					dayjs(runLiveConfig?.calendrier_child[0]?.dateDepart)
						.tz(runLiveConfig?.configuration_calendrier?.heureDebutCourse, true)
						.toDate()
				)
			);
			for (let i = 0, len = point.length; i < len; i++) {
				const pointData = point[i];
				const pointId = pointData.id;
				await deleteLiveConfigPoint(slug, idcalendrier, pointId);
			}
			for (let i = 0, len = segment.length; i < len; i++) {
				const segmentData = segment[i];
				const segmentId = segmentData.idSegment;
				await deleteSegment(slug, idcalendrier, segmentId);
			}

			let i = 0;
			let pointagesLen =
				runLiveConfig?.calendrier_child[index]?.pointages.length;

			while (i < pointagesLen) {
				const pointData = runLiveConfig?.calendrier_child[index]?.pointages[i];

				await postLiveConfigPoint(slug, idcalendrier, {
					id: "new",
					name: pointData.name,
					ligne: pointData.ligne,
					distance: pointData.distance,
					type: pointData.type,
					nombrePassageMin: pointData.nombrePassageMin,
					nombrePassageMax: pointData.nombrePassageMax,
					latitude: pointData.latitude,
					longitude: pointData.longitude,
					radius: pointData.radius,
					handicap: msToTime(pointData.handicap),
					tempsInterpassage: pointData.tempsInterpassage
				});

				i++;
			}
			let j = 0;
			let segmentsLen = runLiveConfig?.calendrier_child[index]?.segments.length;

			while (j < segmentsLen) {
				const segmentData = runLiveConfig?.calendrier_child[index]?.segments[j];

				if (segmentData) {
					await updateSegment(slug, idcalendrier, {
						idSegment: 0,
						libelle: segmentData.libelle,
						idPointageDebut: segmentData.idPointageDebut,
						idPointageFin: segmentData.idPointageFin,
						NumeroPassageDebut: segmentData.NumeroPassageDebut,
						NumeroPassageFin: segmentData.NumeroPassageFin,
						ordre: segmentData.ordre
					});
				}

				j++;
			}
			Toast.success("Configuration ajoutée avec succès");

			unsavedChangesRef.current = false;

			await refetchPoints();
			await refetchSegments();
			setLoading(false);
		} catch (error) {
			await refetchPoints();
			await refetchSegments();
		} finally {
			setLoading(false);
		}
	};
	const currentRaceId = liveConfig?.id;
	const currentIndex = runLiveConfig?.calendrier_child.findIndex(
		(item: any) => item.id === currentRaceId
	);
	return (
		<div className="relative w-full">
			<div className="col-span-1 flex flex-col gap-2 border-b-2 p-5">
				<div className="flex gap-4">
					<p className="font-bold">{trad[lang].configureFromAnotherRace}</p>
					<button
						onClick={toggle}
						className={`flex h-6 w-12 items-center rounded-full p-1 transition-colors duration-300 ${
							isToggled ? "bg-green-500" : "bg-gray-300"
						}`}
					>
						<div
							className={`h-4 w-4 transform rounded-full bg-white shadow-md transition-transform duration-300 ${
								isToggled ? "translate-x-6" : ""
							}`}
						></div>
					</button>
				</div>
				{isToggled ? (
					<div className="flex w-full items-center gap-4">
						<select
							onChange={(e) => importRaceConfig(parseInt(e.target.value))}
							className="h-12 w-full rounded-md border border-gray-300 pl-2 focus:outline-none"
						>
							<option>Choisissez une course</option>
							{runLiveConfig?.calendrier_child
								?.filter((config: any) => config.id !== currentRaceId)
								.map((config: any, index: any) => {
									return (
										config?.pointages?.length > 0 && (
											<option key={index} value={index}>
												{config.nom}
											</option>
										)
									);
								})}
						</select>
						<button onClick={() => importRaceConfig(currentIndex)}>
							<RiArrowGoBackFill size={20} />
						</button>
					</div>
				) : null}
			</div>
			<div
				className={`grid w-full grid-cols-2 gap-5 border-b-2 border-gray-200 p-5 transition-opacity ${
					!loading ? "opacity-100" : "opacity-50"
				}`}
			>
				<div className="col-span-1 flex flex-col gap-2">
					<p className="font-bold">{trad[lang].liveTypeOfRun}</p>

					<select
						value={runForm.type.value}
						onChange={(e) => setFormValue("type", parseInt(e.target.value))}
						className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
					>
						<option value={0}>{trad[lang].chooseCalendarRunType}</option>
						<option value={1}>{trad[lang].chooseCalendarRunTypeSimple}</option>
						<option value={2}>{trad[lang].chooseCalendarRunTypeLaps}</option>
						<option value={3}>
							{trad[lang].chooseCalendarRunTypeSimpleVirtual}
						</option>
						<option value={4}>
							{trad[lang].chooseCalendarRunTypeLapsVirtual}
						</option>
						<option value={5}>
							{trad[lang].chooseCalendarRunTypeTriathlon}
						</option>
					</select>

					{runForm.type.error && (
						<p className="rounded-md bg-red-50 p-3 text-red-600">
							{trad[lang].runFormTypeError}
						</p>
					)}
				</div>

				{runForm.type.value != 3 && runForm.type.value != 4 && (
					<div className="col-span-1 flex flex-col gap-2">
						<p className="font-bold">{trad[lang].calendarRunDistance}</p>

						{runForm.type.value == 5 ? (
							<select
								value={runForm.distance.value}
								onChange={(e) =>
									setFormValue("distance", parseInt(e.target.value))
								}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							>
								<option value={0}>{trad[lang].runFormDistanceType}</option>
								<option value={12900}>XS</option>
								<option value={25750}>S</option>
								<option value={51500}>M</option>
								<option value={113000}>L</option>
								<option value={154000}>XL</option>
								<option value={225995}>XXL</option>
							</select>
						) : (
							<input
								type="number"
								step={1}
								min={0}
								value={runForm.distance.value}
								onChange={(e) =>
									setFormValue("distance", parseInt(e.target.value))
								}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							/>
						)}

						{runForm.distance.error && (
							<p className="rounded-md bg-red-50 p-3 text-red-600">
								{trad[lang].runFormDistanceError}
							</p>
						)}
					</div>
				)}

				{(runForm.type.value == 2 || runForm.type.value == 4) && (
					<div className="col-span-1 flex flex-col gap-2">
						<p className="font-bold">{trad[lang].calendarRunLaps}</p>

						<input
							type="number"
							step={1}
							min={0}
							value={runForm.laps.value}
							onChange={(e) => setFormValue("laps", parseInt(e.target.value))}
							className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
						/>

						{runForm.laps.error && (
							<p className="rounded-md bg-red-50 p-3 text-red-600">
								{trad[lang].runFormLapsError}
							</p>
						)}
					</div>
				)}

				{runForm.type.value != 3 && runForm.type.value != 4 && (
					<div className="col-span-1 flex flex-col gap-2">
						<p className="font-bold">{trad[lang].liveDateOfRun}</p>
						<input
							type="datetime-local"
							step={0.001}
							value={runForm.date.value}
							onChange={handleDateChange}
							className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default LiveConfigurationCalendar;

import { useContext, useEffect, useState } from "react";
import { updateRegistration, updateRegistrationChoices, updateRegistrationOptions } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import { LiveContext } from "../../contexts/LiveContext";
import trad from "../../lang/traduction";
import { FFAErrorCode } from "../../types/FFA";
import { calculateAge } from "../../utils/DateFormater";
import Toast from "../../utils/Toasts";
import RegistrationEditModal from "./RegistrationEditModal";

interface RegistrationEditModalLogicProps {
	refetch?: any;
	editedSubscription?: any;
	setEditedSubscription: Function;
	bibList: number[];
}

const RegistrationEditModalLogic = ({
	editedSubscription,
	refetch,
	setEditedSubscription,
	bibList
}: RegistrationEditModalLogicProps) => {
	const { lang } = useContext(AppContext);
	const { liveConfig } = useContext(LiveContext);
	const [selectedTab, setSelectedTab] = useState<string>("general");
	const [editForm, setEditForm] = useState<any>({});
	const [editFormOptions, setEditFormOptions] = useState<any>({});
	const [editFormChoices, setEditFormChoices] = useState<any>({});
	const [canSave, setCanSave] = useState<boolean>(true);
	const [bibConflict, setBibConflict] = useState<boolean>(false);
	const [birthdateError, setBirthdateError] = useState<boolean>(false);

	const [ppsError, setPpsError] = useState<string>("");
	const [numLicenceError, setNumLicenceError] = useState<string>("");

	const observation = JSON.parse(liveConfig?.observation);

	const conflict: boolean = bibConflict === true;

	useEffect(() => {
		if (selectedTab == "options")
			setCanSave(Object.keys(editFormOptions).length > 0);
        else if (selectedTab == "choices")
            setCanSave(Object.keys(editFormChoices).length > 0);
		else if (selectedTab == "general") {
			let conflict = false;
			let bib = editForm.bib ? parseInt(editForm.bib) : -1;
			if (bib >= 0 && bib != editedSubscription?.bib)
				conflict = bibList.includes(bib);
			setBibConflict(conflict);

			// Check birthdate
			let isBirthdateInvalid = false;
			if (editForm.birthdate) {
				isBirthdateInvalid = calculateAge(editForm.birthdate) < 3;
				setBirthdateError(isBirthdateInvalid);
			} else setBirthdateError(false);

			setCanSave(
				Object.keys(editForm).length > 0 &&
					conflict === false &&
					isBirthdateInvalid === false
			);
		}
	}, [editForm, editFormOptions, editFormChoices, selectedTab]);

	useEffect(() => {
		setEditForm({});
		setEditFormOptions({});
        setEditFormChoices({});
	}, [selectedTab]);

	const getObsData = (property: string) => {
		if (observation) {
			return observation[property];
		}
		return undefined;
	};

	const onSaveClick = async () => {
		try {
			setCanSave(false);

			if (!editedSubscription?.id) {
				throw new Error("No registration id");
			}
			switch (selectedTab) {
				case "general":
					await updateRegistration(editedSubscription.id, editForm);
					break;

				case "options":
					await updateRegistrationOptions(
						editedSubscription.id,
						editFormOptions
					);
					break;
                
                case "choices":
                    await updateRegistrationChoices(
                        editedSubscription.id,
                        editFormChoices
                    );
                    break;
			}

			setEditForm({});
			setEditFormOptions({});
			setEditFormChoices({});
			setEditedSubscription(null);

			Toast.success(trad[lang].updateSuccess);
			await refetch();
		} catch (error: any) {
			if (error.response.data) {
				const axiosError = error.response.data;

				if (axiosError.licence != "") {
					setNumLicenceError(
						trad[lang][`ffa_error_${axiosError.licence as FFAErrorCode}`]
					);
				}

				if (axiosError.pps != "") {
					setPpsError(
						trad[lang][`ffa_error_${axiosError.pps as FFAErrorCode}`]
					);
				}
			}
			console.error(error);
			Toast.error(trad[lang].updateError);
		} finally {
			setCanSave(true);
		}
	};

	const handleInputChange = (field: string, value: string, maths?: boolean) => {
		setEditForm({
			...editForm,
			[field]: maths ? Math.abs(parseInt(value)) : value
		});
	};

	const handleDateChange = (field: string, value: string) => {
		setEditForm({
			...editForm,
			[field]: value + "T00:00:00.000Z"
		});
	};

	const closePopup = () => {
		setEditedSubscription(null);
	};

	return (
		<RegistrationEditModal
			handleInputChange={handleInputChange}
			handleDateChange={handleDateChange}
			closePopup={closePopup}
			onSaveClick={onSaveClick}
			setSelectedTab={setSelectedTab}
			conflict={conflict}
			canSave={canSave}
			birthdateError={birthdateError}
			ppsError={ppsError}
			numLicenceError={numLicenceError}
			selectedTab={selectedTab}
			editedSubscription={editedSubscription}
			editFormOptions={editFormOptions}
			setEditFormOptions={setEditFormOptions}
            editFormChoices={editFormChoices}
            setEditFormChoices={setEditFormChoices}
		/>
	);
};

export default RegistrationEditModalLogic;

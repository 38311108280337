import { Tooltip, Typography } from "@material-tailwind/react";
import dayjs from "dayjs";
import { Fragment, useContext, useMemo, useState } from "react";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { publishStatus } from "../api/admin";
import {
	askValidation,
	duplicateEvent,
	editInvoiceExport,
	getEvent,
	getEventRunList,
	getInvoiceExport,
	patchSubscriptionsStatus,
	unpublishEvent
} from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import DateTimePickerTZ from "../components/common/DateTimePickerTZ";
import PageLoader from "../components/common/PageLoader";
import BibPlanModal from "../components/modals/BibPlanModal";
import AccessGuard, {
	AccessKeys,
	AccessType
} from "../components/navigation/AccessGuard";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import { UserContext } from "../contexts/UserContext";
import trad from "../lang/traduction";
import { classNames } from "../utils/Classes";
import Toast from "../utils/Toasts";

function EventDetails() {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const { user, userIsAdmin, getUser } = useContext(UserContext);
	const navigate = useNavigate();
	const [openBibPlan, setOpenBibPlan] = useState<boolean>(false);
	const [choosedCalendar, setChoosedCalendar] = useState<any>({});
	const [loadingCall, setLoadingCall] = useState(false);
	const [openedAskValidation, setOpenedAskValidation] = useState(false);
	const [openedNewEdition, setOpenedNewEdition] = useState(false);
	const [editionForm, setEditionForm] = useState({
		name: "",
		startDate: dayjs(),
		endDate: dayjs()
	});
	const [alertInvoice, setAlertInvoice] = useState({
		alert: false,
		event: "",
		rapport: ""
	});

	const { data, isLoading, refetch } = useQuery({
		queryKey: [slug],
		queryFn: () => getEvent(slug),
		enabled: !!slug
	});

	const {
		data: dataRuns,
		isLoading: isLoadingRuns,
		refetch: refetchDataRuns
	} = useQuery({
		queryKey: ["event_routes", slug],
		queryFn: () => getEventRunList(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const getInvoiceFile = async () => {
		try {
			// API call
			const { data, headers } = await getInvoiceExport(slug as string);

			const headers_dispo = headers["content-disposition"]?.split(";") || [];

			const headersFileName = headers_dispo
				.find((item: string) => item.includes("filename="))
				.split('"')[1];

			const headersVerified = headers_dispo
				.find((item: string) => item.includes("verified="))
				?.split("=")[1];
			const headersEventTotal = headers_dispo
				.find((item: string) => item.includes("eventTotal="))
				?.split("=")[1];
			const headersRapportTotal = headers_dispo
				.find((item: string) => item.includes("rapportTotal="))
				?.split("=")[1];

			setAlertInvoice({
				alert: headersVerified == "false",
				event: headersEventTotal,
				rapport: headersRapportTotal
			});

			// Convert Blob to File : according to extension...
			const blob = new Blob([data], {
				type: "application/octet-stream"
			});

			const url = URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.download = decodeURI(headersFileName);
			document.body.appendChild(link);
			link.click();

			URL.revokeObjectURL(url);
			link.remove();
		} catch (error) {
			// Toast error
			console.error(error);
			Toast.error(trad[lang].toast_invoice_error);
		}
	};

	const editInvoiceFile = async () => {
		try {
			// API call
			const { data, headers } = await editInvoiceExport(slug as string);

			const headers_dispo = headers["content-disposition"]?.split(";") || [];

			const headersFileName = headers_dispo
				.find((item: string) => item.includes("filename="))
				.split('"')[1];

			const blob = new Blob([data], {
				type: "application/octet-stream"
			});

			const url = URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.download = decodeURI(headersFileName);
			document.body.appendChild(link);
			link.click();

			URL.revokeObjectURL(url);
			link.remove();
		} catch (error) {
			// Toast error
			console.error(error);
			Toast.error(trad[lang].toast_invoice_error);
		}
	};

	const config_options = useMemo(
		() => [
			{
				link: `/${slug}/event-configuration/sport`,
				text: trad[lang].update_presentation,
				permissionKey: AccessKeys.EVENT_IDENTITY,
				accessible: true
			},
			{
				link: `/${slug}/event-configuration/sport/runs`,
				text: trad[lang].update_runs,
				permissionKey: AccessKeys.EVENT_RUNS,
				accessible: true
			},
			{
				link: `/${slug}/event-configuration/sport/contacts`,
				text: trad[lang].update_contacts,
				permissionKey: AccessKeys.EVENT_CONTACTS,
				accessible: true
			},
			{
				link: `/${slug}/event-configuration/sport/ribs`,
				text: trad[lang].update_ribs,
				accessible: true,
				permissionKey: AccessKeys.EVENT_RIB
			},
			{
				link: `/${slug}/event-configuration/sport/sponsors`,
				text: trad[lang].update_sponsors,
				permissionKey: AccessKeys.EVENT_SPONSORS,
				disable: true,
				accessible: true
			},
			{
				link: `${process.env.REACT_APP_CHRONO_URL}/events/${slug}?preview=${
					data?.id_personne + data?.id
				}`,
				text: trad[lang].preview,
				permissionKey: AccessKeys.EVENT_PREVIEW,
				target: "_blank",
				accessible: true
			}
		],
		[data, lang]
	);

	const finance_options = useMemo(
		() => [
			{
				link: "",
				call: getInvoiceFile,
				text: trad[lang].preview_invoice,
				callable: true,
				accessible: user?.roles?.includes("ROLE_ADMIN")
			},
			{
				link: "",
				call: editInvoiceFile,
				text: trad[lang].edit_invoice,
				callable: true,
				accessible: user?.roles?.includes("ROLE_ADMIN")
			},
			{
				link: `/${slug}/commissions`,
				text: trad[lang].commissions,
				// Clé a changer en AccessKeys.EVENT_COMMISSIONS
				// Pas fait pour ne pas changer APICT en preprod (où la clé n'existe pas)
				// Sera ajouté le vendredi 10 novembre
				permissionsKey: AccessKeys.STATS,
				disable: true,
				accessible: true
			},
			{
				link: `/${slug}/donations`,
				text: trad[lang].donations,
				permissionsKey: AccessKeys.STATS,
				disable: true,
				accessible: true
			}
		],
		[data, lang]
	);

	const handleAskValidation = async () => {
		try {
			setLoadingCall(true);
			const { data } = await askValidation(slug as string);

			if (data.document) {
				// Fait telecharger le document
				const link = document.createElement("a");
				link.href = data.document.url;
				link.download = data.document?.name;
				link.target = "_blank";
				document.body.appendChild(link);
				link.click();
				link.remove();
			}

			refetch();

			setOpenedAskValidation(false);

			Toast.success(trad[lang].success_ask_validation);
		} catch (error) {
			Toast.error(trad[lang].error_ask_validation);
		} finally {
			setLoadingCall(false);
		}
	};

	const handleUnpublishEvent = async () => {
		try {
			await unpublishEvent(slug as string);

			refetch();

			Toast.success(trad[lang].success_unpublish_event);
		} catch (error) {
			Toast.error(trad[lang].error_unpublish_event);
		}
	};

	const handlePublish = async (status: boolean) => {
		try {
			setLoadingCall(true);
			await publishStatus(data.slug, status);

			refetch();

			Toast.success(trad[lang].success_publish_event);
		} catch (error) {
			Toast.error(trad[lang].error_unpublish_event);
		} finally {
			setLoadingCall(false);
		}
	};

	const handleStopSubscriptions = async () => {
		try {
			setLoadingCall(true);

			await patchSubscriptionsStatus(slug as string);
			refetch();

			Toast.success(trad[lang].succes_stopsubscription_event);
		} catch (error) {
			Toast.error(trad[lang].error_stopsubscription_event);
		} finally {
			setLoadingCall(false);
		}
	};

	const handleNewEdition = async () => {
		try {
			if (
				!editionForm?.name ||
				!editionForm.startDate ||
				!editionForm.startDate
			) {
				Toast.error(trad[lang].inputs_edition_not_complete);
			}

			setLoadingCall(true);

			const newEvent = await duplicateEvent(data.slug, editionForm);

			setEditionForm({ name: "", startDate: dayjs(), endDate: dayjs() });
			setOpenedNewEdition(false);

			// Refresh droits
			await getUser();

			navigate(`/${newEvent}/event-details`);
		} catch (error) {
			Toast.error(trad[lang].error_new_edition);
		} finally {
			setLoadingCall(false);
		}
	};

	if (isLoading) {
		return <PageLoader menu_key="configuration" />;
	}

	return (
		<Layout
			active_key="configuration"
			bottomBar={<BottomBarNavigation back_to="/" />}
		>
			<BreadCrumb
				items={[
					{
						key: "event",
						text: data?.name,
						to: "#",
						active: true
					}
				]}
			/>

			{/* Title */}
			<h1 className="mt-6 text-2xl font-bold text-gloom md:text-3xl">
				{data?.name}
			</h1>
			<h3 className="mb-6 mt-1 text-gloom">
				{new Date(data.start_date).toLocaleDateString("fr-FR", {
					day: "numeric",
					month: "long",
					year: "numeric",
					timeZone: data.timezone
				})}
			</h3>

			<h2 className="w-full text-lg font-bold text-gloom md:text-xl">
				{trad[lang].config_title}
			</h2>

			<div className="flex w-full flex-row flex-wrap gap-3 py-3">
				{config_options
					.filter((item) => item.accessible)
					.map((item, index) => (
						<AccessGuard
							permissionKey={item.permissionKey as string}
							type={AccessType.BUTTON}
							key={index}
						>
							{item.target ? (
								<Link
									to={item.link}
									target={item.target}
									className="medium w-full select-none rounded-md bg-primary py-2 px-8 text-center text-white transition-colors hover:bg-primarymedium disabled:cursor-not-allowed disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit"
								>
									<p>{item.text}</p>
								</Link>
							) : (
								<button
									disabled={item.disable}
									className="medium w-full select-none rounded-md bg-primary py-2 px-8 text-white transition-colors hover:bg-primarymedium disabled:cursor-not-allowed disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit"
									onClick={() => {
										navigate(item.link);
									}}
								>
									{item.text}
								</button>
							)}
						</AccessGuard>
					))}
			</div>
			<div className="flex w-full flex-col flex-wrap gap-3 sm:flex-row">
				<button
					className="w-full cursor-pointer select-none rounded-md bg-blue-500 py-2 px-8 text-white transition-colors hover:bg-blue-600 disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit"
					onClick={() => setOpenedNewEdition(true)}
				>
					{trad[lang].createNewEdition}
				</button>

				<AccessGuard
					permissionKey={AccessKeys.SUBSCRIPTIONS_STATUS}
					type={AccessType.BUTTON}
				>
					<button
						className={`w-full cursor-pointer select-none rounded-md py-2 px-8 text-white transition-colors disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit ${
							data.stop_inscription
								? "bg-green-500 hover:bg-green-600"
								: "bg-red-500 hover:bg-red-600"
						}`}
						onClick={handleStopSubscriptions}
						disabled={loadingCall}
					>
						{data.stop_inscription
							? trad[lang].activateSubscriptions
							: trad[lang].disableSubscriptions}
					</button>
				</AccessGuard>

				{userIsAdmin ? (
					<>
						{data.is_active ? (
							<button
								className="w-full cursor-pointer select-none rounded-md bg-red-500 py-2 px-8 text-white transition-colors hover:bg-red-600 disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit"
								onClick={() => handlePublish(false)}
								disabled={loadingCall}
							>
								{trad[lang].unpublishEvent}
							</button>
						) : (
							<button
								className="w-full cursor-pointer select-none rounded-md bg-green-500 py-2 px-8 text-white transition-colors hover:bg-green-600 disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit"
								onClick={() => handlePublish(true)}
								disabled={loadingCall}
							>
								{trad[lang].publishEvent}
							</button>
						)}
					</>
				) : (
					<>
						{!data.en_attente_validation && !data.is_active && (
							<AccessGuard
								permissionKey={AccessKeys.EVENT_PUBLISH}
								type={AccessType.BUTTON}
							>
								<button
									className="cursor-pointer select-none rounded-md bg-green-500 py-2 px-8 text-white transition-colors hover:bg-green-600 disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400"
									onClick={() => setOpenedAskValidation(true)}
								>
									{trad[lang].ask_validation}
								</button>
							</AccessGuard>
						)}

						{data.en_attente_validation && !data.is_active && (
							<div className="select-none rounded-md bg-green-500 py-2 px-8 text-white opacity-50 transition-colors">
								{trad[lang].validation_asked}
							</div>
						)}

						{data.is_active && (
							<AccessGuard
								permissionKey={AccessKeys.EVENT_UNPUBLISH}
								type={AccessType.BUTTON}
							>
								<button
									className="cursor-pointer select-none rounded-md bg-red-500 py-2 px-8 text-white transition-colors hover:bg-red-600"
									onClick={handleUnpublishEvent}
								>
									{trad[lang].unpublish}
								</button>
							</AccessGuard>
						)}
					</>
				)}
			</div>

			<h2 className="mt-4 w-full text-lg font-bold text-gloom md:text-xl">
				{trad[lang].invoice_title}
			</h2>

			<div className="flex w-full flex-row flex-wrap gap-3 py-3">
				{finance_options
					.filter((item) => item.accessible)
					.map((item, index) => (
						<Fragment key={index}>
							{item.callable ? (
								<div
									className="medium w-full cursor-pointer select-none rounded-md bg-primary py-2 px-8 text-center text-white transition-colors hover:bg-primarymedium disabled:cursor-not-allowed disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit"
									onClick={item.call}
								>
									{item.text}
								</div>
							) : (
								<AccessGuard
									permissionKey={item.permissionsKey || "none"}
									type={AccessType.BUTTON}
								>
									<Link
										to={item.link}
										className="medium w-full select-none rounded-md bg-primary py-2 px-8 text-center text-white transition-colors hover:bg-primarymedium disabled:cursor-not-allowed disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit"
									>
										{item.text}
									</Link>
								</AccessGuard>
							)}
						</Fragment>
					))}
			</div>

			<h2 className="mt-2 w-full text-lg font-bold text-gloom md:text-xl">
				{trad[lang].runs_title}
			</h2>

			{dataRuns?.calendrier_child?.map((item: any) => (
				<div
					key={item.id}
					className={classNames(
						"flex w-full flex-col items-center gap-4 border-b p-2",
						item.index % 2 ? "bg-gray-100" : ""
					)}
				>
					<h3 className="w-full">{item.nom}</h3>

					<div className="flex w-full flex-col gap-3 sm:flex-row">
						{/* PLAN NUMEROTATION */}
						<div className="flex w-full flex-row sm:w-fit">
							<AccessGuard
								permissionKey={AccessKeys.EVENT_NUMBERING_PLAN}
								type={AccessType.BUTTON}
							>
								<button
									onClick={() => {
										setOpenBibPlan(true);
										setChoosedCalendar(item);
									}}
									className="w-full gap-2 rounded-md bg-primary py-2 px-3 text-white hover:bg-primarymedium disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-500 sm:w-fit"
								>
									{trad[lang].bibPlan}
								</button>
							</AccessGuard>
						</div>

						{/* OPTIONS */}
						<div className="flex w-full flex-row sm:w-fit">
							{!item.id_produit ? (
								<Tooltip
									className="text-md z-50 text-center"
									content={
										<Typography>{trad[lang].warning_no_price}</Typography>
									}
								>
									<Link
										to={``}
										className="w-full cursor-not-allowed gap-2 rounded-md bg-gray-300 py-2 px-3 text-white sm:w-fit"
									>
										{trad[lang].configure_options}
									</Link>
								</Tooltip>
							) : (
								<AccessGuard
									permissionKey={AccessKeys.EVENT_OPTIONS}
									type={AccessType.BUTTON}
								>
									<Link
										to={`/${slug}/event-configuration/sport/options/${item.slug}`}
										className="w-full gap-2 rounded-md bg-primary py-2 px-3 text-center text-white hover:bg-primarymedium disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit"
									>
										{trad[lang].configure_options}
									</Link>
								</AccessGuard>
							)}
						</div>

						{/* Gestion des listes */}
						<div className="flex w-full flex-row sm:w-fit">
							<Tooltip
								className="text-md z-50 text-center"
								content={<Typography>{trad[lang].lists_desc}</Typography>}
							>
								<Link
									to={`/${slug}/event-configuration/${item.slug}/lists`}
									className="w-full gap-2 rounded-md bg-primary py-2 px-3 text-center text-white hover:bg-primarymedium disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit"
								>
									{trad[lang].configure_lists}
								</Link>
							</Tooltip>
						</div>
					</div>
				</div>
			))}

			{openBibPlan && (
				<BibPlanModal
					open={openBibPlan}
					setOpen={setOpenBibPlan}
					lang={lang}
					refetch={refetchDataRuns}
					calendar={choosedCalendar}
					setChoosedCalendar={setChoosedCalendar}
				/>
			)}

			{dataRuns?.calendrier_child?.length > 0 && (
				<div className="flex w-full flex-row items-center justify-between gap-4 p-2">
					<h3></h3>
					<div className="flex flex-row">
						<Link to={`/${slug}/event-configuration/sport/options`}>
							<AccessGuard
								permissionKey={AccessKeys.EVENT_OPTIONS}
								type={AccessType.BUTTON}
							>
								<button className="gap-2 rounded-md bg-primary py-2 px-3 text-white hover:bg-primarymedium disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400">
									{trad[lang].add_global_option}
								</button>
							</AccessGuard>
						</Link>
					</div>
				</div>
			)}

			{openedAskValidation && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
					<div className="w-[80%] max-w-screen-lg rounded-md border bg-white">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h3 className="text-xl font-semibold text-gray-900">
								{trad[lang].ask_validation}
							</h3>

							<button
								type="button"
								className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
								onClick={() => setOpenedAskValidation(false)}
							>
								<AiOutlineClose size={16} />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="flex flex-col gap-6 p-6">
							<p>
								{trad[lang].contract_explanation}
								<a
									href={`mailto:sales@dag-system.com`}
									className="text-primary hover:underline"
								>
									sales@dag-system.com
								</a>
							</p>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								type="button"
								className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
								onClick={() => setOpenedAskValidation(false)}
							>
								{trad[lang].cancel}
							</button>
							<button
								type="button"
								className={`rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300 ${
									loadingCall && "opacity-50"
								}`}
								onClick={handleAskValidation}
								disabled={loadingCall}
							>
								{loadingCall ? (
									<p className="flex flex-row items-center gap-2">
										<AiOutlineLoading3Quarters className="animate-spin" />
										{trad[lang].loading}
									</p>
								) : (
									trad[lang].downloadContractAskValidation
								)}
							</button>
						</div>
					</div>
				</div>
			)}

			{openedNewEdition && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
					<div className="w-[80%] max-w-screen-lg rounded-md border bg-white">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h3 className="text-xl font-semibold text-gray-900">
								{trad[lang].createNewEdition}
							</h3>

							<button
								type="button"
								className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
								onClick={() => setOpenedNewEdition(false)}
							>
								<AiOutlineClose size={16} />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="flex flex-col gap-3 p-6">
							{/* Nom du nouvel évènement */}
							<div className={`flex w-full flex-col`}>
								<label
									className="mb-1 flex flex-row items-center gap-1 text-lg"
									htmlFor="event_run_name"
								>
									{trad[lang].event_name_label}
								</label>

								<input
									type="text"
									id="event_run_name"
									className={classNames("rounded-md")}
									onChange={(e) =>
										setEditionForm((old) => ({ ...old, name: e.target.value }))
									}
									value={editionForm?.name}
								/>
							</div>
							<div className="flex flex-col gap-3 md:flex-row">
								{/* Date de début */}
								<div className={`flex w-full flex-col`}>
									<label
										className="mb-1 flex flex-row items-center gap-1 text-lg"
										htmlFor="event_run_name"
									>
										{trad[lang].event_start_date_label}
									</label>

									<DateTimePickerTZ
										handleChange={(e) => {
											if (e) {
												setEditionForm((old) => ({ ...old, startDate: e }));

												if (dayjs(editionForm.endDate).isBefore(dayjs(e))) {
													setEditionForm((old) => ({ ...old, endDate: e }));
												}
											}
										}}
										value={editionForm.startDate}
										timezone={data.timezone}
									/>
								</div>
								{/* Date de fin */}
								<div className={`flex w-full flex-col`}>
									<label
										className="mb-1 flex flex-row items-center gap-1 text-lg"
										htmlFor="event_run_name"
									>
										{trad[lang].event_end_date_label}
									</label>

									<DateTimePickerTZ
										handleChange={(e) => {
											if (e) {
												setEditionForm((old) => ({ ...old, endDate: e }));
											}
										}}
										value={editionForm.endDate}
										timezone={data.timezone}
									/>
								</div>
							</div>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								type="button"
								className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
								onClick={() => setOpenedNewEdition(false)}
							>
								{trad[lang].cancel}
							</button>
							<button
								type="button"
								className={`rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300 ${
									loadingCall && "opacity-50"
								}`}
								onClick={handleNewEdition}
								disabled={loadingCall}
							>
								{loadingCall ? (
									<p className="flex flex-row items-center gap-2">
										<AiOutlineLoading3Quarters className="animate-spin" />
										{trad[lang].loading}
									</p>
								) : (
									trad[lang].createNewEdition
								)}
							</button>
						</div>
					</div>
				</div>
			)}

			{alertInvoice.alert && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
					<div className="w-[80%] max-w-screen-lg rounded-md border bg-white">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<button
								type="button"
								className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
								onClick={() =>
									setAlertInvoice({ alert: false, event: "", rapport: "" })
								}
							>
								<AiOutlineClose size={16} />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="flex flex-col gap-3 p-6">
							<p className="font-bold">
								Attention, la facture n'est pas valide.
							</p>
							<p>
								Les totaux encaissés du rapport et de la facture ne
								correspondent pas :
							</p>
							<p>
								<strong>Facture :</strong> {alertInvoice.event} € TTC
							</p>
							<p>
								<strong>Rapport :</strong> {alertInvoice.rapport} € TTC
							</p>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								type="button"
								className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
								onClick={() =>
									setAlertInvoice({ alert: false, event: "", rapport: "" })
								}
							>
								{trad[lang].close}
							</button>
						</div>
					</div>
				</div>
			)}
		</Layout>
	);
}

export default EventDetails;

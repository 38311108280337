import { useContext } from "react";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";

const ManageRunnerPagination = () => {
	const {
		LiveData,
		debouncedSearch,
		pagination,
		page,
		nmbPerPage,
		handleChangePage,
		maxPage
	} = useContext(ManageRunnerContext);

	return !!LiveData.result.length ? (
		<>
			<div className="relative right-[9%] mx-auto flex items-center justify-center gap-3">
				{!pagination.includes(1) && (
					<>
						<p className="cursor-pointer" onClick={() => handleChangePage(1)}>
							1
						</p>
					</>
				)}

				{pagination.map((item) => {
					if (item == page) {
						return (
							<select
								value={page}
								onChange={(e) => {
									handleChangePage(parseInt(e.target.value));
								}}
								className="h-[56px] w-10 appearance-none border-t-0 border-b-0 bg-none p-0 text-center"
								key={item}
							>
								{Array.from(
									{
										length: Math.ceil(LiveData.count / nmbPerPage)
									},
									(_, i) => i + 1
								).map((item) => (
									<option value={item} key={item}>
										{item}
									</option>
								))}
							</select>
						);
					}

					return (
						<p
							className={`${item == page ? "font-bold" : ""} cursor-pointer`}
							onClick={() => handleChangePage(item)}
							key={item}
						>
							{item}
						</p>
					);
				})}

				{!pagination.includes(maxPage) && (
					<p
						className="cursor-pointer"
						onClick={() => handleChangePage(maxPage)}
						key={maxPage}
					>
						{maxPage}
					</p>
				)}
			</div>
		</>
	) : (
		<></>
	);
};

export default ManageRunnerPagination;

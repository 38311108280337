// COUNTRIES LIST WITH NAMES TRANSLATED IN en AND fr WITH THEIR RESPECTIVE FLAGS
export interface ICountry {
	name: {
		fr: string;
		en: string;
		original: string;
	};
	flag: string;
	code: string;
	idWindev: number;
	alpha3Code: string;
	ffaCode: string;
	area: string;
}

export interface IcountriesList {
	[key: string]: ICountry;
}

export const getCountryByWindevId = (
	idWindev: number
): ICountry | undefined => {
	// Fix type of idWindev in case of it is a string
	if (typeof idWindev === "string" && (idWindev as string).match(/^[0-9]+$/))
		idWindev = parseInt(idWindev as string);
	let item: ICountry | undefined = countriesList.find(
		(country) => country.idWindev === idWindev
	);
	if (item) return item;
	return undefined;
};

export const getCountryNameByWindevId = (
	idWindev: number,
	lang: "fr" | "en" | "original"
): string => {
	let country: ICountry | undefined = getCountryByWindevId(idWindev);
	if (country) return country.name[lang];
	return "";
};

export const getWindevIdByCountryName = (name: string): number => {
	let n = name.toLowerCase();
	let country: ICountry | undefined = countriesList.find(
		(country) =>
			country.name.en.toLowerCase() === n || country.name.fr.toLowerCase() === n
	);
	if (country) return country.idWindev;
	country = countriesList.find(
		(country) =>
			country.code.toLowerCase() === n || country.alpha3Code.toLowerCase() === n
	);
	if (country) return country.idWindev;

	// If no country found, default to France (74)
	return getWindevIdByCountryName("France");
};

export const countriesList: ICountry[] = [
	{
		name: { fr: "Afghanistan", en: "Afghanistan", original: "AFGHANISTAN" },
		ffaCode: "AFG",
		code: "AF",
		area: "ASI",
		idWindev: 1,
		flag: "https://flagpedia.net/data/flags/small/af.png",
		alpha3Code: "AFG"
	},
	{
		name: { fr: "ANGUILLA", en: "ANGUILLA", original: "ANGUILLA" },
		ffaCode: "AIA",
		code: "AIA",
		area: "NAM",
		idWindev: 1000,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Koweït", en: "Kuwait", original: "KUWAIT" },
		ffaCode: "KUW",
		code: "KW",
		area: "ASI",
		idWindev: 118,
		flag: "https://flagpedia.net/data/flags/small/kw.png",
		alpha3Code: "KWT"
	},
	{
		name: { fr: "Albanie", en: "Albania", original: "ALBANIA" },
		ffaCode: "ALB",
		code: "AL",
		area: "EUR",
		idWindev: 3,
		flag: "https://flagpedia.net/data/flags/small/al.png",
		alpha3Code: "ALB"
	},
	{
		name: { fr: "Laos", en: "Laos", original: "LAOS" },
		ffaCode: "LAO",
		code: "LA",
		area: "ASI",
		idWindev: 120,
		flag: "https://flagpedia.net/data/flags/small/la.png",
		alpha3Code: "LAO"
	},
	{
		name: { fr: "Algérie", en: "Algeria", original: "ALGERIA" },
		ffaCode: "ALG",
		code: "DZ",
		area: "AFR",
		idWindev: 4,
		flag: "https://flagpedia.net/data/flags/small/dz.png",
		alpha3Code: "DZA"
	},
	{
		name: { fr: "Lettonie", en: "Latvia", original: "LATVIA" },
		ffaCode: "LAT",
		code: "LV",
		area: "EUR",
		idWindev: 121,
		flag: "https://flagpedia.net/data/flags/small/lv.png",
		alpha3Code: "LVA"
	},
	{
		name: { fr: "Andorre", en: "Andorra", original: "ANDORRA" },
		ffaCode: "AND",
		code: "AD",
		area: "EUR",
		idWindev: 6,
		flag: "https://flagpedia.net/data/flags/small/ad.png",
		alpha3Code: "AND"
	},
	{
		name: { fr: "Libye", en: "Libya", original: "LIBYA" },
		ffaCode: "LBA",
		code: "LY",
		area: "AFR",
		idWindev: 125,
		flag: "https://flagpedia.net/data/flags/small/ly.png",
		alpha3Code: "LBY"
	},
	{
		name: { fr: "Angola", en: "Angola", original: "ANGOLA" },
		ffaCode: "ANG",
		code: "AO",
		area: "AFR",
		idWindev: 7,
		flag: "https://flagpedia.net/data/flags/small/ao.png",
		alpha3Code: "AGO"
	},
	{
		name: { fr: "Liban", en: "Lebanon", original: "LEBANON" },
		ffaCode: "LBN",
		code: "LB",
		area: "ASI",
		idWindev: 122,
		flag: "https://flagpedia.net/data/flags/small/lb.png",
		alpha3Code: "LBN"
	},
	{
		name: {
			fr: "Antigua-et-Barbuda",
			en: "Antigua and Barbuda",
			original: "ANTIGUA & BARBUDA"
		},
		ffaCode: "ANT",
		code: "AG",
		area: "NAM",
		idWindev: 10,
		flag: "https://flagpedia.net/data/flags/small/ag.png",
		alpha3Code: "ATG"
	},
	{
		name: { fr: "Liberia", en: "Liberia", original: "LIBERIA" },
		ffaCode: "LBR",
		code: "LR",
		area: "AFR",
		idWindev: 124,
		flag: "https://flagpedia.net/data/flags/small/lr.png",
		alpha3Code: "LBR"
	},
	{
		name: { fr: "Argentine", en: "Argentina", original: "ARGENTINA" },
		ffaCode: "ARG",
		code: "AR",
		area: "SAM",
		idWindev: 11,
		flag: "https://flagpedia.net/data/flags/small/ar.png",
		alpha3Code: "ARG"
	},
	{
		name: { fr: "Sainte-Lucie", en: "Saint Lucia", original: "SAINT LUCIA" },
		ffaCode: "LCA",
		code: "LC",
		area: "NAM",
		idWindev: 188,
		flag: "https://flagpedia.net/data/flags/small/lc.png",
		alpha3Code: "LCA"
	},
	{
		name: { fr: "Arménie", en: "Armenia", original: "ARMENIA" },
		ffaCode: "ARM",
		code: "AM",
		area: "EUR",
		idWindev: 12,
		flag: "https://flagpedia.net/data/flags/small/am.png",
		alpha3Code: "ARM"
	},
	{
		name: { fr: "Lesotho", en: "Lesotho", original: "LESOTHO" },
		ffaCode: "LES",
		code: "LS",
		area: "AFR",
		idWindev: 123,
		flag: "https://flagpedia.net/data/flags/small/ls.png",
		alpha3Code: "LSO"
	},
	{
		name: { fr: "ARUBA", en: "ARUBA", original: "ARUBA" },
		ffaCode: "ARU",
		code: "ARU",
		area: "NAM",
		idWindev: 1001,
		flag: "",
		alpha3Code: ""
	},
	{
		name: {
			fr: "Liechtenstein",
			en: "Liechtenstein",
			original: "LIECHTENSTEIN"
		},
		ffaCode: "LIE",
		code: "LI",
		area: "EUR",
		idWindev: 126,
		flag: "https://flagpedia.net/data/flags/small/li.png",
		alpha3Code: "LIE"
	},
	{
		name: {
			fr: "AMERICAN SAMOA",
			en: "AMERICAN SAMOA",
			original: "AMERICAN SAMOA"
		},
		ffaCode: "ASA",
		code: "ASA",
		area: "OCE",
		idWindev: 1002,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Lituanie", en: "Lithuania", original: "LITHUANIA" },
		ffaCode: "LTU",
		code: "LT",
		area: "EUR",
		idWindev: 127,
		flag: "https://flagpedia.net/data/flags/small/lt.png",
		alpha3Code: "LTU"
	},
	{
		name: { fr: "Australie", en: "Australia", original: "AUSTRALIA" },
		ffaCode: "AUS",
		code: "AU",
		area: "OCE",
		idWindev: 14,
		flag: "https://flagpedia.net/data/flags/small/au.png",
		alpha3Code: "AUS"
	},
	{
		name: { fr: "Luxembourg", en: "Luxembourg", original: "LUXEMBOURG" },
		ffaCode: "LUX",
		code: "LU",
		area: "EUR",
		idWindev: 128,
		flag: "https://flagpedia.net/data/flags/small/lu.png",
		alpha3Code: "LUX"
	},
	{
		name: { fr: "Autriche", en: "Austria", original: "AUSTRIA" },
		ffaCode: "AUT",
		code: "AT",
		area: "EUR",
		idWindev: 15,
		flag: "https://flagpedia.net/data/flags/small/at.png",
		alpha3Code: "AUT"
	},
	{
		name: { fr: "MACAO, CHINA", en: "MACAO, CHINA", original: "MACAO, CHINA" },
		ffaCode: "MAC",
		code: "MAC",
		area: "ASI",
		idWindev: 1003,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Azerbaïdjan", en: "Azerbaijan", original: "AZERBAIJAN" },
		ffaCode: "AZE",
		code: "AZ",
		area: "EUR",
		idWindev: 16,
		flag: "https://flagpedia.net/data/flags/small/az.png",
		alpha3Code: "AZE"
	},
	{
		name: { fr: "Madagascar", en: "Madagascar", original: "MADAGASCAR" },
		ffaCode: "MAD",
		code: "MG",
		area: "AFR",
		idWindev: 131,
		flag: "https://flagpedia.net/data/flags/small/mg.png",
		alpha3Code: "MDG"
	},
	{
		name: { fr: "Bahamas", en: "Bahamas", original: "BAHAMAS" },
		ffaCode: "BAH",
		code: "BS",
		area: "NAM",
		idWindev: 17,
		flag: "https://flagpedia.net/data/flags/small/bs.png",
		alpha3Code: "BHS"
	},
	{
		name: { fr: "Maroc", en: "Morocco", original: "MOROCCO" },
		ffaCode: "MAR",
		code: "MA",
		area: "AFR",
		idWindev: 149,
		flag: "https://flagpedia.net/data/flags/small/ma.png",
		alpha3Code: "MAR"
	},
	{
		name: { fr: "Bangladesh", en: "Bangladesh", original: "BANGLADESH" },
		ffaCode: "BAN",
		code: "BD",
		area: "ASI",
		idWindev: 19,
		flag: "https://flagpedia.net/data/flags/small/bd.png",
		alpha3Code: "BGD"
	},
	{
		name: { fr: "Malaisie", en: "Malaysia", original: "MALAYSIA" },
		ffaCode: "MAS",
		code: "MY",
		area: "ASI",
		idWindev: 133,
		flag: "https://flagpedia.net/data/flags/small/my.png",
		alpha3Code: "MYS"
	},
	{
		name: { fr: "Barbade", en: "Barbados", original: "BARBADOS" },
		ffaCode: "BAR",
		code: "BB",
		area: "NAM",
		idWindev: 20,
		flag: "https://flagpedia.net/data/flags/small/bb.png",
		alpha3Code: "BRB"
	},
	{
		name: { fr: "Malawi", en: "Malawi", original: "MALAWI" },
		ffaCode: "MAW",
		code: "MW",
		area: "AFR",
		idWindev: 132,
		flag: "https://flagpedia.net/data/flags/small/mw.png",
		alpha3Code: "MWI"
	},
	{
		name: { fr: "Burundi", en: "Burundi", original: "BURUNDI" },
		ffaCode: "BDI",
		code: "BI",
		area: "AFR",
		idWindev: 36,
		flag: "https://flagpedia.net/data/flags/small/bi.png",
		alpha3Code: "BDI"
	},
	{
		name: { fr: "Moldavie", en: "Moldova", original: "MOLDOVA" },
		ffaCode: "MDA",
		code: "MD",
		area: "EUR",
		idWindev: 144,
		flag: "https://flagpedia.net/data/flags/small/md.png",
		alpha3Code: "MDA"
	},
	{
		name: { fr: "Belgique", en: "Belgium", original: "BELGIUM" },
		ffaCode: "BEL",
		code: "BE",
		area: "EUR",
		idWindev: 22,
		flag: "https://flagpedia.net/data/flags/small/be.png",
		alpha3Code: "BEL"
	},
	{
		name: { fr: "Maldives", en: "Maldives", original: "MALDIVES" },
		ffaCode: "MDV",
		code: "MV",
		area: "ASI",
		idWindev: 134,
		flag: "https://flagpedia.net/data/flags/small/mv.png",
		alpha3Code: "MDV"
	},
	{
		name: { fr: "Bénin", en: "Benin", original: "BENIN" },
		ffaCode: "BEN",
		code: "BJ",
		area: "AFR",
		idWindev: 24,
		flag: "https://flagpedia.net/data/flags/small/bj.png",
		alpha3Code: "BEN"
	},
	{
		name: { fr: "Mexique", en: "Mexico", original: "MEXICO" },
		ffaCode: "MEX",
		code: "MX",
		area: "NAM",
		idWindev: 142,
		flag: "https://flagpedia.net/data/flags/small/mx.png",
		alpha3Code: "MEX"
	},
	{
		name: { fr: "BERMUDA", en: "BERMUDA", original: "BERMUDA" },
		ffaCode: "BER",
		code: "BER",
		area: "NAM",
		idWindev: 1004,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Mongolie", en: "Mongolia", original: "MONGOLIA" },
		ffaCode: "MGL",
		code: "MN",
		area: "ASI",
		idWindev: 146,
		flag: "https://flagpedia.net/data/flags/small/mn.png",
		alpha3Code: "MNG"
	},
	{
		name: { fr: "Bhoutan", en: "Bhutan", original: "BHUTAN" },
		ffaCode: "BHU",
		code: "BT",
		area: "ASI",
		idWindev: 26,
		flag: "https://flagpedia.net/data/flags/small/bt.png",
		alpha3Code: "BTN"
	},
	{
		name: {
			fr: "Îles Marshall",
			en: "Marshall Islands",
			original: "MARSHALL ISLANDS"
		},
		ffaCode: "MHL",
		code: "MH",
		area: "OCE",
		idWindev: 137,
		flag: "https://flagpedia.net/data/flags/small/mh.png",
		alpha3Code: "MHL"
	},
	{
		name: {
			fr: "Bosnie-Herzégovine",
			en: "Bosnia and Herzegovina",
			original: "BOSNIA-HERZEGOVINA"
		},
		ffaCode: "BIH",
		code: "BA",
		area: "EUR",
		idWindev: 28,
		flag: "https://flagpedia.net/data/flags/small/ba.png",
		alpha3Code: "BIH"
	},
	{
		name: {
			fr: "NORTH MACEDONIA",
			en: "NORTH MACEDONIA",
			original: "NORTH MACEDONIA"
		},
		ffaCode: "MKD",
		code: "MKD",
		area: "EUR",
		idWindev: 1005,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Belize", en: "Belize", original: "BELIZE" },
		ffaCode: "BIZ",
		code: "BZ",
		area: "NAM",
		idWindev: 23,
		flag: "https://flagpedia.net/data/flags/small/bz.png",
		alpha3Code: "BLZ"
	},
	{
		name: { fr: "Mali", en: "Mali", original: "MALI" },
		ffaCode: "MLI",
		code: "ML",
		area: "AFR",
		idWindev: 135,
		flag: "https://flagpedia.net/data/flags/small/ml.png",
		alpha3Code: "MLI"
	},
	{
		name: { fr: "Biélorussie", en: "Belarus", original: "BELARUS" },
		ffaCode: "BLR",
		code: "BY",
		area: "EUR",
		idWindev: 21,
		flag: "https://flagpedia.net/data/flags/small/by.png",
		alpha3Code: "BLR"
	},
	{
		name: { fr: "Malte", en: "Malta", original: "MALTA" },
		ffaCode: "MLT",
		code: "MT",
		area: "EUR",
		idWindev: 136,
		flag: "https://flagpedia.net/data/flags/small/mt.png",
		alpha3Code: "MLT"
	},
	{
		name: { fr: "Bolivie", en: "Bolivia", original: "BOLIVIA" },
		ffaCode: "BOL",
		code: "BO",
		area: "SAM",
		idWindev: 27,
		flag: "https://flagpedia.net/data/flags/small/bo.png",
		alpha3Code: "BOL"
	},
	{
		name: { fr: "Monténégro", en: "Montenegro", original: "MONTENEGRO" },
		ffaCode: "MNE",
		code: "ME",
		area: "EUR",
		idWindev: 147,
		flag: "https://flagpedia.net/data/flags/small/me.png",
		alpha3Code: "MNE"
	},
	{
		name: { fr: "Botswana", en: "Botswana", original: "BOTSWANA" },
		ffaCode: "BOT",
		code: "BW",
		area: "AFR",
		idWindev: 29,
		flag: "https://flagpedia.net/data/flags/small/bw.png",
		alpha3Code: "BWA"
	},
	{
		name: { fr: "MONTSERRAT", en: "MONTSERRAT", original: "MONTSERRAT" },
		ffaCode: "MNT",
		code: "MNT",
		area: "NAM",
		idWindev: 1006,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Brésil", en: "Brazil", original: "BRAZIL" },
		ffaCode: "BRA",
		code: "BR",
		area: "SAM",
		idWindev: 31,
		flag: "https://flagpedia.net/data/flags/small/br.png",
		alpha3Code: "BRA"
	},
	{
		name: { fr: "Monaco", en: "Monaco", original: "MONACO" },
		ffaCode: "MON",
		code: "MC",
		area: "EUR",
		idWindev: 145,
		flag: "https://flagpedia.net/data/flags/small/mc.png",
		alpha3Code: "MCO"
	},
	{
		name: { fr: "Bahreïn", en: "Bahrain", original: "BAHRAIN" },
		ffaCode: "BRN",
		code: "BH",
		area: "ASI",
		idWindev: 18,
		flag: "https://flagpedia.net/data/flags/small/bh.png",
		alpha3Code: "BHR"
	},
	{
		name: { fr: "Mozambique", en: "Mozambique", original: "MOZAMBIQUE" },
		ffaCode: "MOZ",
		code: "MZ",
		area: "AFR",
		idWindev: 150,
		flag: "https://flagpedia.net/data/flags/small/mz.png",
		alpha3Code: "MOZ"
	},
	{
		name: { fr: "Brunéi", en: "Brunei", original: "BRUNEI" },
		ffaCode: "BRU",
		code: "BN",
		area: "ASI",
		idWindev: 33,
		flag: "https://flagpedia.net/data/flags/small/bn.png",
		alpha3Code: "BRN"
	},
	{
		name: { fr: "Maurice", en: "Mauritius", original: "MAURITIUS" },
		ffaCode: "MRI",
		code: "MU",
		area: "AFR",
		idWindev: 140,
		flag: "https://flagpedia.net/data/flags/small/mu.png",
		alpha3Code: "MUS"
	},
	{
		name: { fr: "Bulgarie", en: "Bulgaria", original: "BULGARIA" },
		ffaCode: "BUL",
		code: "BG",
		area: "EUR",
		idWindev: 34,
		flag: "https://flagpedia.net/data/flags/small/bg.png",
		alpha3Code: "BGR"
	},
	{
		name: { fr: "Mauritanie", en: "Mauritania", original: "MAURITANIA" },
		ffaCode: "MTN",
		code: "MR",
		area: "AFR",
		idWindev: 139,
		flag: "https://flagpedia.net/data/flags/small/mr.png",
		alpha3Code: "MRT"
	},
	{
		name: { fr: "Burkina Faso", en: "Burkina Faso", original: "BURKINA FASO" },
		ffaCode: "BUR",
		code: "BF",
		area: "AFR",
		idWindev: 35,
		flag: "https://flagpedia.net/data/flags/small/bf.png",
		alpha3Code: "BFA"
	},
	{
		name: {
			fr: "Birmanie (Myanmar)",
			en: "Burma (Myanmar)",
			original: "MYANMAR"
		},
		ffaCode: "MYA",
		code: "MM",
		area: "ASI",
		idWindev: 151,
		flag: "https://flagpedia.net/data/flags/small/mm.png",
		alpha3Code: "MMR"
	},
	{
		name: {
			fr: "République centrafricaine",
			en: "Central African Republic",
			original: "CENTRAL AFRICAN REPUBLIC"
		},
		ffaCode: "CAF",
		code: "CF",
		area: "AFR",
		idWindev: 42,
		flag: "https://flagpedia.net/data/flags/small/cf.png",
		alpha3Code: "CAF"
	},
	{
		name: { fr: "Namibie", en: "Namibia", original: "NAMIBIA" },
		ffaCode: "NAM",
		code: "NA",
		area: "AFR",
		idWindev: 152,
		flag: "https://flagpedia.net/data/flags/small/na.png",
		alpha3Code: "NAM"
	},
	{
		name: { fr: "Cambodge", en: "Cambodia", original: "CAMBODIA" },
		ffaCode: "CAM",
		code: "KH",
		area: "ASI",
		idWindev: 37,
		flag: "https://flagpedia.net/data/flags/small/kh.png",
		alpha3Code: "KHM"
	},
	{
		name: { fr: "Colombie", en: "Colombia", original: "COLOMBIA" },
		ffaCode: "COL",
		code: "CO",
		area: "AMS", // Amérique du Sud
		idWindev: 1024,
		flag: "https://flagpedia.net/data/flags/small/co.png",
		alpha3Code: "COL"
	},
	{
		name: { fr: "Nicaragua", en: "Nicaragua", original: "NICARAGUA" },
		ffaCode: "NCA",
		code: "NI",
		area: "NAM",
		idWindev: 159,
		flag: "https://flagpedia.net/data/flags/small/ni.png",
		alpha3Code: "NIC"
	},
	{
		name: { fr: "Canada", en: "Canada", original: "CANADA" },
		ffaCode: "CAN",
		code: "CA",
		area: "NAM",
		idWindev: 39,
		flag: "https://flagpedia.net/data/flags/small/ca.png",
		alpha3Code: "CAN"
	},
	{
		name: { fr: "Pays-Bas", en: "Netherlands", original: "NETHERLANDS" },
		ffaCode: "NED",
		code: "NL",
		area: "EUR",
		idWindev: 155,
		flag: "https://flagpedia.net/data/flags/small/nl.png",
		alpha3Code: "NLD"
	},
	{
		name: {
			fr: "CAYMAN ISLANDS",
			en: "CAYMAN ISLANDS",
			original: "CAYMAN ISLANDS"
		},
		ffaCode: "CAY",
		code: "CAY",
		area: "NAM",
		idWindev: 1007,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Népal", en: "Nepal", original: "NEPAL" },
		ffaCode: "NEP",
		code: "NP",
		area: "ASI",
		idWindev: 154,
		flag: "https://flagpedia.net/data/flags/small/np.png",
		alpha3Code: "NPL"
	},
	{
		name: {
			fr: "Congo (Brazzaville)",
			en: "Congo (Brazzaville)",
			original: "CONGO"
		},
		ffaCode: "CGO",
		code: "CG",
		area: "AFR",
		idWindev: 50,
		flag: "https://flagpedia.net/data/flags/small/cg.png",
		alpha3Code: "COG"
	},
	{
		name: {
			fr: "NORFOLK ISLAND",
			en: "NORFOLK ISLAND",
			original: "NORFOLK ISLAND"
		},
		ffaCode: "NFK",
		code: "NFK",
		area: "OCE",
		idWindev: 1008,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Costa Rica", en: "Costa Rica", original: "COSTA RICA" },
		ffaCode: "CRC",
		code: "CR",
		area: "NAM",
		idWindev: 53,
		flag: "https://flagpedia.net/data/flags/small/cr.png",
		alpha3Code: "CRI"
	},
	{
		name: { fr: "Niger", en: "Niger", original: "NIGER" },
		ffaCode: "NER",
		code: "NE",
		area: "AFR",
		idWindev: 160,
		flag: "https://flagpedia.net/data/flags/small/ne.png",
		alpha3Code: "NER"
	},
	{
		name: { fr: "Croatie", en: "Croatia", original: "CROATIA" },
		ffaCode: "CRO",
		code: "HR",
		area: "EUR",
		idWindev: 55,
		flag: "https://flagpedia.net/data/flags/small/hr.png",
		alpha3Code: "HRV"
	},
	{
		name: { fr: "Nigéria", en: "Nigeria", original: "NIGERIA" },
		ffaCode: "NGA",
		code: "NG",
		area: "AFR",
		idWindev: 161,
		flag: "https://flagpedia.net/data/flags/small/ng.png",
		alpha3Code: "NGA"
	},
	{
		name: { fr: "Cuba", en: "Cuba", original: "CUBA" },
		ffaCode: "CUB",
		code: "CU",
		area: "NAM",
		idWindev: 56,
		flag: "https://flagpedia.net/data/flags/small/cu.png",
		alpha3Code: "CUB"
	},
	{
		name: { fr: "Norvège", en: "Norway", original: "NORWAY" },
		ffaCode: "NOR",
		code: "NO",
		area: "EUR",
		idWindev: 165,
		flag: "https://flagpedia.net/data/flags/small/no.png",
		alpha3Code: "NOR"
	},
	{
		name: { fr: "Chypre", en: "Cyprus", original: "CYPRUS" },
		ffaCode: "CYP",
		code: "CY",
		area: "ASI",
		idWindev: 57,
		flag: "https://flagpedia.net/data/flags/small/cy.png",
		alpha3Code: "CYP"
	},
	{
		name: { fr: "Oman", en: "Oman", original: "OMAN" },
		ffaCode: "OMA",
		code: "OM",
		area: "ASI",
		idWindev: 166,
		flag: "https://flagpedia.net/data/flags/small/om.png",
		alpha3Code: "OMN"
	},
	{
		name: {
			fr: "République tchèque",
			en: "Czech Republic",
			original: "CZECH REPUBLIC"
		},
		ffaCode: "CZE",
		code: "CZ",
		area: "EUR",
		idWindev: 58,
		flag: "https://flagpedia.net/data/flags/small/cz.png",
		alpha3Code: "CZE"
	},
	{
		name: { fr: "Pakistan", en: "Pakistan", original: "PAKISTAN" },
		ffaCode: "PAK",
		code: "PK",
		area: "ASI",
		idWindev: 167,
		flag: "https://flagpedia.net/data/flags/small/pk.png",
		alpha3Code: "PAK"
	},
	{
		name: { fr: "Danemark", en: "Denmark", original: "DENMARK" },
		ffaCode: "DEN",
		code: "DK",
		area: "EUR",
		idWindev: 59,
		flag: "https://flagpedia.net/data/flags/small/dk.png",
		alpha3Code: "DNK"
	},
	{
		name: { fr: "Palaos", en: "Palau", original: "PALAU" },
		ffaCode: "PLW",
		code: "PW",
		area: "OCE",
		idWindev: 168,
		flag: "https://flagpedia.net/data/flags/small/pw.png",
		alpha3Code: "PLW"
	},
	{
		name: { fr: "Djibouti", en: "Djibouti", original: "DJIBOUTI" },
		ffaCode: "DJI",
		code: "DJ",
		area: "AFR",
		idWindev: 60,
		flag: "https://flagpedia.net/data/flags/small/dj.png",
		alpha3Code: "DJI"
	},
	{
		name: { fr: "Panama", en: "Panama", original: "PANAMA" },
		ffaCode: "PAN",
		code: "PA",
		area: "NAM",
		idWindev: 170,
		flag: "https://flagpedia.net/data/flags/small/pa.png",
		alpha3Code: "PAN"
	},
	{
		name: { fr: "Dominique", en: "Dominica", original: "DOMINICA" },
		ffaCode: "DMA",
		code: "DM",
		area: "NAM",
		idWindev: 61,
		flag: "https://flagpedia.net/data/flags/small/dm.png",
		alpha3Code: "DMA"
	},
	{
		name: {
			fr: "Papouasie-Nouvelle-Guinée",
			en: "Papua New Guinea",
			original: "PAPUA NEW GUINEA"
		},
		ffaCode: "PNG",
		code: "PG",
		area: "OCE",
		idWindev: 171,
		flag: "https://flagpedia.net/data/flags/small/pg.png",
		alpha3Code: "PNG"
	},
	{
		name: {
			fr: "République dominicaine",
			en: "Dominican Republic",
			original: "DOMINICAN REPUBLIC"
		},
		ffaCode: "DOM",
		code: "DO",
		area: "NAM",
		idWindev: 62,
		flag: "https://flagpedia.net/data/flags/small/do.png",
		alpha3Code: "DOM"
	},
	{
		name: { fr: "Paraguay", en: "Paraguay", original: "PARAGUAY" },
		ffaCode: "PAR",
		code: "PY",
		area: "SAM",
		idWindev: 172,
		flag: "https://flagpedia.net/data/flags/small/py.png",
		alpha3Code: "PRY"
	},
	{
		name: {
			fr: "Timor-Leste (Timor Oriental)",
			en: "Timor-Leste (East Timor)",
			original: "EAST TIMOR"
		},
		ffaCode: "TMP",
		code: "TL",
		area: "ASI",
		idWindev: 220,
		flag: "https://flagpedia.net/data/flags/small/tl.png",
		alpha3Code: "TLS"
	},
	{
		name: { fr: "Pérou", en: "Peru", original: "PERU" },
		ffaCode: "PER",
		code: "PE",
		area: "SAM",
		idWindev: 173,
		flag: "https://flagpedia.net/data/flags/small/pe.png",
		alpha3Code: "PER"
	},
	{
		name: { fr: "Équateur", en: "Ecuador", original: "ECUADOR" },
		ffaCode: "ECU",
		code: "EC",
		area: "SAM",
		idWindev: 63,
		flag: "https://flagpedia.net/data/flags/small/ec.png",
		alpha3Code: "ECU"
	},
	{
		name: { fr: "Philippines", en: "Philippines", original: "PHILIPPINES" },
		ffaCode: "PHI",
		code: "PH",
		area: "ASI",
		idWindev: 174,
		flag: "https://flagpedia.net/data/flags/small/ph.png",
		alpha3Code: "PHL"
	},
	{
		name: { fr: "Égypte", en: "Egypt", original: "EGYPT" },
		ffaCode: "EGY",
		code: "EG",
		area: "AFR",
		idWindev: 64,
		flag: "https://flagpedia.net/data/flags/small/eg.png",
		alpha3Code: "EGY"
	},
	{
		name: { fr: "Pologne", en: "Poland", original: "POLAND" },
		ffaCode: "POL",
		code: "PL",
		area: "EUR",
		idWindev: 176,
		flag: "https://flagpedia.net/data/flags/small/pl.png",
		alpha3Code: "POL"
	},
	{
		name: { fr: "El Salvador", en: "El Salvador", original: "EL SALVADOR" },
		ffaCode: "SLV",
		code: "SV",
		area: "NAM",
		idWindev: 65,
		flag: "https://flagpedia.net/data/flags/small/sv.png",
		alpha3Code: "SLV"
	},
	{
		name: { fr: "Portugal", en: "Portugal", original: "PORTUGAL" },
		ffaCode: "POR",
		code: "PT",
		area: "EUR",
		idWindev: 177,
		flag: "https://flagpedia.net/data/flags/small/pt.png",
		alpha3Code: "PRT"
	},
	{
		name: {
			fr: "EQUATORIAL GUINEA",
			en: "EQUATORIAL GUINEA",
			original: "EQUATORIAL GUINEA"
		},
		ffaCode: "GNQ",
		code: "GNQ",
		area: "AFR",
		idWindev: 1009,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "PUERTO RICO", en: "PUERTO RICO", original: "PUERTO RICO" },
		ffaCode: "PRI",
		code: "PRI",
		area: "NAM",
		idWindev: 1010,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Érythrée", en: "Eritrea", original: "ERITREA" },
		ffaCode: "ERI",
		code: "ER",
		area: "AFR",
		idWindev: 67,
		flag: "https://flagpedia.net/data/flags/small/er.png",
		alpha3Code: "ERI"
	},
	{
		name: { fr: "Qatar", en: "Qatar", original: "QATAR" },
		ffaCode: "QAT",
		code: "QA",
		area: "ASI",
		idWindev: 179,
		flag: "https://flagpedia.net/data/flags/small/qa.png",
		alpha3Code: "QAT"
	},
	{
		name: { fr: "Estonie", en: "Estonia", original: "ESTONIA" },
		ffaCode: "EST",
		code: "EE",
		area: "EUR",
		idWindev: 68,
		flag: "https://flagpedia.net/data/flags/small/ee.png",
		alpha3Code: "EST"
	},
	{
		name: { fr: "REUNION", en: "REUNION", original: "REUNION" },
		ffaCode: "REU",
		code: "REU",
		area: "AFR",
		idWindev: 1011,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Eswatini", en: "Eswatini", original: "ESWATINI" },
		ffaCode: "SWZ",
		code: "SZ",
		area: "AFR",
		idWindev: 212,
		flag: "https://flagpedia.net/data/flags/small/sz.png",
		alpha3Code: "SWZ"
	},
	{
		name: { fr: "Roumanie", en: "Romania", original: "ROMANIA" },
		ffaCode: "ROU",
		code: "RO",
		area: "EUR",
		idWindev: 181,
		flag: "https://flagpedia.net/data/flags/small/ro.png",
		alpha3Code: "ROU"
	},
	{
		name: { fr: "Éthiopie", en: "Ethiopia", original: "ETHIOPIA" },
		ffaCode: "ETH",
		code: "ET",
		area: "AFR",
		idWindev: 69,
		flag: "https://flagpedia.net/data/flags/small/et.png",
		alpha3Code: "ETH"
	},
	{
		name: { fr: "Russie", en: "Russia", original: "RUSSIAN FEDERATION" },
		ffaCode: "RUS",
		code: "RU",
		area: "EUR",
		idWindev: 182,
		flag: "https://flagpedia.net/data/flags/small/ru.png",
		alpha3Code: "RUS"
	},
	{
		name: {
			fr: "FALKLAND ISLANDS",
			en: "FALKLAND ISLANDS",
			original: "FALKLAND ISLANDS"
		},
		ffaCode: "FLK",
		code: "FLK",
		area: "SAM",
		idWindev: 1012,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Rwanda", en: "Rwanda", original: "RWANDA" },
		ffaCode: "RWA",
		code: "RW",
		area: "AFR",
		idWindev: 183,
		flag: "https://flagpedia.net/data/flags/small/rw.png",
		alpha3Code: "RWA"
	},
	{
		name: {
			fr: "FAROE ISLANDS",
			en: "FAROE ISLANDS",
			original: "FAROE ISLANDS"
		},
		ffaCode: "FRO",
		code: "FRO",
		area: "EUR",
		idWindev: 1013,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "SAINT HELENA", en: "SAINT HELENA", original: "SAINT HELENA" },
		ffaCode: "SHN",
		code: "SHN",
		area: "AFR",
		idWindev: 1014,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Fidji", en: "Fiji", original: "FIJI" },
		ffaCode: "FIJ",
		code: "FJ",
		area: "OCE",
		idWindev: 72,
		flag: "https://flagpedia.net/data/flags/small/fj.png",
		alpha3Code: "FJI"
	},
	{
		name: {
			fr: "Saint-Kitts-et-Nevis",
			en: "Saint Kitts and Nevis",
			original: "SAINT KITTS & NEVIS"
		},
		ffaCode: "KNA",
		code: "KN",
		area: "NAM",
		idWindev: 187,
		flag: "https://flagpedia.net/data/flags/small/kn.png",
		alpha3Code: "KNA"
	},
	{
		name: { fr: "Finlande", en: "Finland", original: "FINLAND" },
		ffaCode: "FIN",
		code: "FI",
		area: "EUR",
		idWindev: 73,
		flag: "https://flagpedia.net/data/flags/small/fi.png",
		alpha3Code: "FIN"
	},
	{
		name: { fr: "France", en: "France", original: "FRANCE" },
		ffaCode: "FRA",
		code: "FR",
		area: "EUR",
		idWindev: 74,
		flag: "https://flagpedia.net/data/flags/small/fr.png",
		alpha3Code: "FRA"
	},
	{
		name: {
			fr: "SAINT PIERRE AND MIQUELON",
			en: "SAINT PIERRE AND MIQUELON",
			original: "SAINT PIERRE AND MIQUELON"
		},
		ffaCode: "SPM",
		code: "SPM",
		area: "NAM",
		idWindev: 1015,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Gabon", en: "Gabon", original: "GABON" },
		ffaCode: "GAB",
		code: "GA",
		area: "AFR",
		idWindev: 78,
		flag: "https://flagpedia.net/data/flags/small/ga.png",
		alpha3Code: "GAB"
	},
	{
		name: {
			fr: "Saint-Vincent-et-les-Grenadines",
			en: "Saint Vincent and the Grenadines",
			original: "SAINT VINCENT & GRENADINES"
		},
		ffaCode: "VIN",
		code: "VC",
		area: "NAM",
		idWindev: 190,
		flag: "https://flagpedia.net/data/flags/small/vc.png",
		alpha3Code: "VCT"
	},
	{
		name: { fr: "Gambie", en: "Gambia", original: "GAMBIA" },
		ffaCode: "GAM",
		code: "GM",
		area: "AFR",
		idWindev: 79,
		flag: "https://flagpedia.net/data/flags/small/gm.png",
		alpha3Code: "GMB"
	},
	{
		name: { fr: "Samoa", en: "Samoa", original: "Samoa" },
		ffaCode: "WSM",
		code: "WS",
		area: "OCE",
		idWindev: 191,
		flag: "https://flagpedia.net/data/flags/small/ws.png",
		alpha3Code: "WSM"
	},
	{
		name: { fr: "Géorgie", en: "Georgia", original: "GEORGIA" },
		ffaCode: "GEO",
		code: "GE",
		area: "EUR",
		idWindev: 80,
		flag: "https://flagpedia.net/data/flags/small/ge.png",
		alpha3Code: "GEO"
	},
	{
		name: { fr: "Saint-Marin", en: "San Marino", original: "SAN MARINO" },
		ffaCode: "SMR",
		code: "SM",
		area: "EUR",
		idWindev: 192,
		flag: "https://flagpedia.net/data/flags/small/sm.png",
		alpha3Code: "SMR"
	},
	{
		name: { fr: "Allemagne", en: "Germany", original: "GERMANY" },
		ffaCode: "DEU",
		code: "DE",
		area: "EUR",
		idWindev: 81,
		flag: "https://flagpedia.net/data/flags/small/de.png",
		alpha3Code: "DEU"
	},
	{
		name: {
			fr: "Sao Tomé-et-Principe",
			en: "Sao Tome and Principe",
			original: "SÃO TOMÉ AND PRÍNCIPE"
		},
		ffaCode: "STP",
		code: "ST",
		area: "AFR",
		idWindev: 194,
		flag: "https://flagpedia.net/data/flags/small/st.png",
		alpha3Code: "STP"
	},
	{
		name: { fr: "Ghana", en: "Ghana", original: "GHANA" },
		ffaCode: "GHA",
		code: "GH",
		area: "AFR",
		idWindev: 82,
		flag: "https://flagpedia.net/data/flags/small/gh.png",
		alpha3Code: "GHA"
	},
	{
		name: {
			fr: "Arabie Saoudite",
			en: "Saudi Arabia",
			original: "SAUDI ARABIA"
		},
		ffaCode: "KSA",
		code: "SA",
		area: "ASI",
		idWindev: 195,
		flag: "https://flagpedia.net/data/flags/small/sa.png",
		alpha3Code: "SAU"
	},
	{
		name: { fr: "GIBRALTAR", en: "GIBRALTAR", original: "GIBRALTAR" },
		ffaCode: "GIB",
		code: "GIB",
		area: "EUR",
		idWindev: 1016,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Sénégal", en: "Senegal", original: "SENEGAL" },
		ffaCode: "SEN",
		code: "SN",
		area: "AFR",
		idWindev: 196,
		flag: "https://flagpedia.net/data/flags/small/sn.png",
		alpha3Code: "SEN"
	},
	{
		name: { fr: "Grèce", en: "Greece", original: "GREECE" },
		ffaCode: "GRE",
		code: "GR",
		area: "EUR",
		idWindev: 84,
		flag: "https://flagpedia.net/data/flags/small/gr.png",
		alpha3Code: "GRC"
	},
	{
		name: { fr: "Serbie", en: "Serbia", original: "SERBIA" },
		ffaCode: "SRB",
		code: "RS",
		area: "EUR",
		idWindev: 197,
		flag: "https://flagpedia.net/data/flags/small/rs.png",
		alpha3Code: "SRB"
	},
	{
		name: { fr: "GREENLAND", en: "GREENLAND", original: "GREENLAND" },
		ffaCode: "GRL",
		code: "GRL",
		area: "NAM",
		idWindev: 1017,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Seychelles", en: "Seychelles", original: "SEYCHELLES" },
		ffaCode: "SYC",
		code: "SC",
		area: "AFR",
		idWindev: 198,
		flag: "https://flagpedia.net/data/flags/small/sc.png",
		alpha3Code: "SYC"
	},
	{
		name: { fr: "Grenade", en: "Grenada", original: "GRENADA" },
		ffaCode: "GRD",
		code: "GD",
		area: "NAM",
		idWindev: 86,
		flag: "https://flagpedia.net/data/flags/small/gd.png",
		alpha3Code: "GRD"
	},
	{
		name: { fr: "Sierra Leone", en: "Sierra Leone", original: "SIERRA LEONE" },
		ffaCode: "SLE",
		code: "SL",
		area: "AFR",
		idWindev: 199,
		flag: "https://flagpedia.net/data/flags/small/sl.png",
		alpha3Code: "SLE"
	},
	{
		name: { fr: "GUAM", en: "GUAM", original: "GUAM" },
		ffaCode: "GUM",
		code: "GUM",
		area: "OCE",
		idWindev: 1018,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Singapour", en: "Singapore", original: "SINGAPORE" },
		ffaCode: "SGP",
		code: "SG",
		area: "ASI",
		idWindev: 200,
		flag: "https://flagpedia.net/data/flags/small/sg.png",
		alpha3Code: "SGP"
	},
	{
		name: { fr: "Guatemala", en: "Guatemala", original: "GUATEMALA" },
		ffaCode: "GTM",
		code: "GT",
		area: "NAM",
		idWindev: 89,
		flag: "https://flagpedia.net/data/flags/small/gt.png",
		alpha3Code: "GTM"
	},
	{
		name: { fr: "Slovaquie", en: "Slovakia", original: "SLOVAKIA" },
		ffaCode: "SVK",
		code: "SK",
		area: "EUR",
		idWindev: 201,
		flag: "https://flagpedia.net/data/flags/small/sk.png",
		alpha3Code: "SVK"
	},
	{
		name: { fr: "Guinée", en: "Guinea", original: "GUINEA" },
		ffaCode: "GIN",
		code: "GN",
		area: "AFR",
		idWindev: 91,
		flag: "https://flagpedia.net/data/flags/small/gn.png",
		alpha3Code: "GIN"
	},
	{
		name: { fr: "Slovénie", en: "Slovenia", original: "SLOVENIA" },
		ffaCode: "SVN",
		code: "SI",
		area: "EUR",
		idWindev: 202,
		flag: "https://flagpedia.net/data/flags/small/si.png",
		alpha3Code: "SVN"
	},
	{
		name: {
			fr: "Guinée-Bissau",
			en: "Guinea-Bissau",
			original: "GUINEA-BISSAU"
		},
		ffaCode: "GNB",
		code: "GW",
		area: "AFR",
		idWindev: 92,
		flag: "https://flagpedia.net/data/flags/small/gw.png",
		alpha3Code: "GNB"
	},
	{
		name: {
			fr: "Afrique du Sud",
			en: "South Africa",
			original: "SOUTH AFRICA"
		},
		ffaCode: "ZAF",
		code: "ZA",
		area: "AFR",
		idWindev: 205,
		flag: "https://flagpedia.net/data/flags/small/za.png",
		alpha3Code: "ZAF"
	},
	{
		name: { fr: "Guyana", en: "Guyana", original: "GUYANA" },
		ffaCode: "GUY",
		code: "GY",
		area: "SAM",
		idWindev: 93,
		flag: "https://flagpedia.net/data/flags/small/gy.png",
		alpha3Code: "GUY"
	},
	{
		name: { fr: "Soudan du Sud", en: "South Sudan", original: "SOUTH SUDAN" },
		ffaCode: "SSD",
		code: "SS",
		area: "AFR",
		idWindev: 209,
		flag: "https://flagpedia.net/data/flags/small/ss.png",
		alpha3Code: "SDN"
	},
	{
		name: { fr: "Haïti", en: "Haiti", original: "HAITI" },
		ffaCode: "HTI",
		code: "HT",
		area: "NAM",
		idWindev: 94,
		flag: "https://flagpedia.net/data/flags/small/ht.png",
		alpha3Code: "HTI"
	},
	{
		name: { fr: "Espagne", en: "Spain", original: "SPAIN" },
		ffaCode: "ESP",
		code: "ES",
		area: "EUR",
		idWindev: 207,
		flag: "https://flagpedia.net/data/flags/small/es.png",
		alpha3Code: "ESP"
	},
	{
		name: { fr: "Honduras", en: "Honduras", original: "HONDURAS" },
		ffaCode: "HND",
		code: "HN",
		area: "NAM",
		idWindev: 97,
		flag: "https://flagpedia.net/data/flags/small/hn.png",
		alpha3Code: "HND"
	},
	{
		name: { fr: "Sri Lanka", en: "Sri Lanka", original: "SRI LANKA" },
		ffaCode: "LKA",
		code: "LK",
		area: "ASI",
		idWindev: 208,
		flag: "https://flagpedia.net/data/flags/small/lk.png",
		alpha3Code: "LKA"
	},
	{
		name: { fr: "HONG KONG", en: "HONG KONG", original: "HONG KONG" },
		ffaCode: "HKG",
		code: "HKG",
		area: "ASI",
		idWindev: 1019,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Hongrie", en: "Hungary", original: "HUNGARY" },
		ffaCode: "HUN",
		code: "HU",
		area: "EUR",
		idWindev: 99,
		flag: "https://flagpedia.net/data/flags/small/hu.png",
		alpha3Code: "HUN"
	},
	{
		name: { fr: "Suriname", en: "Suriname", original: "SURINAME" },
		ffaCode: "SUR",
		code: "SR",
		area: "SAM",
		idWindev: 210,
		flag: "https://flagpedia.net/data/flags/small/sr.png",
		alpha3Code: "SUR"
	},
	{
		name: { fr: "Islande", en: "Iceland", original: "ICELAND" },
		ffaCode: "ISL",
		code: "IS",
		area: "EUR",
		idWindev: 100,
		flag: "https://flagpedia.net/data/flags/small/is.png",
		alpha3Code: "ISL"
	},
	{
		name: { fr: "Suède", en: "Sweden", original: "SWEDEN" },
		ffaCode: "SWE",
		code: "SE",
		area: "EUR",
		idWindev: 213,
		flag: "https://flagpedia.net/data/flags/small/se.png",
		alpha3Code: "SWE"
	},
	{
		name: { fr: "Inde", en: "India", original: "INDIA" },
		ffaCode: "IND",
		code: "IN",
		area: "ASI",
		idWindev: 101,
		flag: "https://flagpedia.net/data/flags/small/in.png",
		alpha3Code: "IND"
	},
	{
		name: { fr: "Suisse", en: "Switzerland", original: "SWITZERLAND" },
		ffaCode: "CHE",
		code: "CH",
		area: "EUR",
		idWindev: 214,
		flag: "https://flagpedia.net/data/flags/small/ch.png",
		alpha3Code: "CHE"
	},
	{
		name: { fr: "Indonésie", en: "Indonesia", original: "INDONESIA" },
		ffaCode: "IDN",
		code: "ID",
		area: "ASI",
		idWindev: 102,
		flag: "https://flagpedia.net/data/flags/small/id.png",
		alpha3Code: "IDN"
	},
	{
		name: { fr: "Syrie", en: "Syria", original: "SYRIA" },
		ffaCode: "SYR",
		code: "SY",
		area: "ASI",
		idWindev: 215,
		flag: "https://flagpedia.net/data/flags/small/sy.png",
		alpha3Code: "SYR"
	},
	{
		name: { fr: "Iran", en: "Iran", original: "IRAN" },
		ffaCode: "IRN",
		code: "IR",
		area: "ASI",
		idWindev: 103,
		flag: "https://flagpedia.net/data/flags/small/ir.png",
		alpha3Code: "IRN"
	},
	{
		name: { fr: "Taïwan", en: "Taiwan", original: "TAIWAN" },
		ffaCode: "TWN",
		code: "TW",
		area: "ASI",
		idWindev: 216,
		flag: "https://flagpedia.net/data/flags/small/tw.png",
		alpha3Code: "TWN"
	},
	{
		name: { fr: "Irak", en: "Iraq", original: "IRAQ" },
		ffaCode: "IRQ",
		code: "IQ",
		area: "ASI",
		idWindev: 104,
		flag: "https://flagpedia.net/data/flags/small/iq.png",
		alpha3Code: "IRQ"
	},
	{
		name: { fr: "Tadjikistan", en: "Tajikistan", original: "TAJIKISTAN" },
		ffaCode: "TJK",
		code: "TJ",
		area: "ASI",
		idWindev: 217,
		flag: "https://flagpedia.net/data/flags/small/tj.png",
		alpha3Code: "TJK"
	},
	{
		name: { fr: "Irlande", en: "Ireland", original: "IRELAND" },
		ffaCode: "IRL",
		code: "IE",
		area: "EUR",
		idWindev: 105,
		flag: "https://flagpedia.net/data/flags/small/ie.png",
		alpha3Code: "IRL"
	},
	{
		name: { fr: "Tanzanie", en: "Tanzania", original: "TANZANIA" },
		ffaCode: "TZA",
		code: "TZ",
		area: "AFR",
		idWindev: 218,
		flag: "https://flagpedia.net/data/flags/small/tz.png",
		alpha3Code: "TZA"
	},
	{
		name: { fr: "Israël", en: "Israel", original: "ISRAEL" },
		ffaCode: "ISR",
		code: "IL",
		area: "ASI",
		idWindev: 107,
		flag: "https://flagpedia.net/data/flags/small/il.png",
		alpha3Code: "ISR"
	},
	{
		name: { fr: "Thaïlande", en: "Thailand", original: "THAILAND" },
		ffaCode: "THA",
		code: "TH",
		area: "ASI",
		idWindev: 219,
		flag: "https://flagpedia.net/data/flags/small/th.png",
		alpha3Code: "THA"
	},
	{
		name: { fr: "Italie", en: "Italy", original: "ITALY" },
		ffaCode: "ITA",
		code: "IT",
		area: "EUR",
		idWindev: 108,
		flag: "https://flagpedia.net/data/flags/small/it.png",
		alpha3Code: "ITA"
	},
	{
		name: { fr: "Togo", en: "Togo", original: "TOGO" },
		ffaCode: "TGO",
		code: "TG",
		area: "AFR",
		idWindev: 221,
		flag: "https://flagpedia.net/data/flags/small/tg.png",
		alpha3Code: "TGO"
	},
	{
		name: { fr: "Jamaïque", en: "Jamaica", original: "JAMAICA" },
		ffaCode: "JAM",
		code: "JM",
		area: "NAM",
		idWindev: 109,
		flag: "https://flagpedia.net/data/flags/small/jm.png",
		alpha3Code: "JAM"
	},
	{
		name: { fr: "TOKELAU", en: "TOKELAU", original: "TOKELAU" },
		ffaCode: "TKL",
		code: "TKL",
		area: "OCE",
		idWindev: 1020,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Japon", en: "Japan", original: "JAPAN" },
		ffaCode: "JPN",
		code: "JP",
		area: "ASI",
		idWindev: 110,
		flag: "https://flagpedia.net/data/flags/small/jp.png",
		alpha3Code: "JPN"
	},
	{
		name: { fr: "Tonga", en: "Tonga", original: "TONGA" },
		ffaCode: "TON",
		code: "TO",
		area: "OCE",
		idWindev: 223,
		flag: "https://flagpedia.net/data/flags/small/to.png",
		alpha3Code: "TON"
	},
	{
		name: { fr: "Jordanie", en: "Jordan", original: "JORDAN" },
		ffaCode: "JOR",
		code: "JO",
		area: "ASI",
		idWindev: 112,
		flag: "https://flagpedia.net/data/flags/small/jo.png",
		alpha3Code: "JOR"
	},
	{
		name: {
			fr: "Trinité-et-Tobago",
			en: "Trinidad and Tobago",
			original: "TRINIDAD AND TOBAGO"
		},
		ffaCode: "TTO",
		code: "TT",
		area: "NAM",
		idWindev: 224,
		flag: "https://flagpedia.net/data/flags/small/tt.png",
		alpha3Code: "TTO"
	},
	{
		name: { fr: "Kazakhstan", en: "Kazakhstan", original: "KAZAKHSTAN" },
		ffaCode: "KAZ",
		code: "KZ",
		area: "ASI",
		idWindev: 113,
		flag: "https://flagpedia.net/data/flags/small/kz.png",
		alpha3Code: "KAZ"
	},
	{
		name: { fr: "Tunisie", en: "Tunisia", original: "TUNISIA" },
		ffaCode: "TUN",
		code: "TN",
		area: "AFR",
		idWindev: 225,
		flag: "https://flagpedia.net/data/flags/small/tn.png",
		alpha3Code: "TUN"
	},
	{
		name: { fr: "Kenya", en: "Kenya", original: "KENYA" },
		ffaCode: "KEN",
		code: "KE",
		area: "AFR",
		idWindev: 114,
		flag: "https://flagpedia.net/data/flags/small/ke.png",
		alpha3Code: "KEN"
	},
	{
		name: { fr: "Turkménistan", en: "Turkmenistan", original: "TURKMENISTAN" },
		ffaCode: "TKM",
		code: "TM",
		area: "ASI",
		idWindev: 227,
		flag: "https://flagpedia.net/data/flags/small/tm.png",
		alpha3Code: "TKM"
	},
	{
		name: { fr: "Kiribati", en: "Kiribati", original: "KIRIBATI" },
		ffaCode: "KIR",
		code: "KI",
		area: "OCE",
		idWindev: 115,
		flag: "https://flagpedia.net/data/flags/small/ki.png",
		alpha3Code: "KIR"
	},
	{
		name: { fr: "Tuvalu", en: "Tuvalu", original: "TUVALU" },
		ffaCode: "TUV",
		code: "TV",
		area: "OCE",
		idWindev: 229,
		flag: "https://flagpedia.net/data/flags/small/tv.png",
		alpha3Code: "TUV"
	},
	{
		name: { fr: "KOSOVO", en: "KOSOVO", original: "KOSOVO" },
		ffaCode: "XKX",
		code: "XKX",
		area: "EUR",
		idWindev: 1021,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Ouganda", en: "Uganda", original: "UGANDA" },
		ffaCode: "UGA",
		code: "UG",
		area: "AFR",
		idWindev: 230,
		flag: "https://flagpedia.net/data/flags/small/ug.png",
		alpha3Code: "UGA"
	},
	{
		name: { fr: "KUA", en: "KUA", original: "KUA" },
		ffaCode: "KUA",
		code: "KUA",
		area: "NAM",
		idWindev: 1022,
		flag: "",
		alpha3Code: ""
	},
	{
		name: { fr: "Ukraine", en: "Ukraine", original: "UKRAINE" },
		ffaCode: "UKR",
		code: "UA",
		area: "EUR",
		idWindev: 231,
		flag: "https://flagpedia.net/data/flags/small/ua.png",
		alpha3Code: "UKR"
	},
	{
		name: { fr: "KURDISTAN", en: "KURDISTAN", original: "KURDISTAN" },
		ffaCode: "KUR",
		code: "KUR",
		area: "ASI",
		idWindev: 1023,
		flag: "",
		alpha3Code: ""
	},
	{
		name: {
			fr: "Émirats Arabes Unis",
			en: "United Arab Emirates",
			original: "UNITED ARAB EMIRATES"
		},
		ffaCode: "ARE",
		code: "AE",
		area: "ASI",
		idWindev: 232,
		flag: "https://flagpedia.net/data/flags/small/ae.png",
		alpha3Code: "ARE"
	},
	{
		name: { fr: "Kirghizistan", en: "Kyrgyzstan", original: "KYRGYZSTAN" },
		ffaCode: "KGZ",
		code: "KG",
		area: "ASI",
		idWindev: 119,
		flag: "https://flagpedia.net/data/flags/small/kg.png",
		alpha3Code: "KGZ"
	},
	{
		name: {
			fr: "Royaume-Uni",
			en: "United Kingdom",
			original: "UNITED KINGDOM"
		},
		ffaCode: "GBR",
		code: "GB",
		area: "EUR",
		idWindev: 233,
		flag: "https://flagpedia.net/data/flags/small/gb.png",
		alpha3Code: "GBR"
	},
	{
		name: { fr: "Uruguay", en: "Uruguay", original: "URUGUAY" },
		ffaCode: "URY",
		code: "UY",
		area: "SAM",
		idWindev: 236,
		flag: "https://flagpedia.net/data/flags/small/uy.png",
		alpha3Code: "URY"
	},
	{
		name: { fr: "États-Unis", en: "United States", original: "USA" },
		ffaCode: "USA",
		code: "US",
		area: "NAM",
		idWindev: 234,
		flag: "https://flagpedia.net/data/flags/small/us.png",
		alpha3Code: "USA"
	},
	{
		name: { fr: "Ouzbékistan", en: "Uzbekistan", original: "UZBEKISTAN" },
		ffaCode: "UZB",
		code: "UZ",
		area: "ASI",
		idWindev: 237,
		flag: "https://flagpedia.net/data/flags/small/uz.png",
		alpha3Code: "UZB"
	},
	{
		name: {
			fr: "Cité du Vatican",
			en: "Vatican City",
			original: "VATICAN CITY"
		},
		ffaCode: "VAT",
		code: "VA",
		area: "EUR",
		idWindev: 96,
		flag: "https://flagpedia.net/data/flags/small/va.png",
		alpha3Code: "VAT"
	},
	{
		name: { fr: "Venezuela", en: "Venezuela", original: "VENEZUELA" },
		ffaCode: "VEN",
		code: "VE",
		area: "SAM",
		idWindev: 240,
		flag: "https://flagpedia.net/data/flags/small/ve.png",
		alpha3Code: "VEN"
	},
	{
		name: { fr: "Viêt Nam", en: "Vietnam", original: "VIETNAM" },
		ffaCode: "VNM",
		code: "VN",
		area: "ASI",
		idWindev: 241,
		flag: "https://flagpedia.net/data/flags/small/vn.png",
		alpha3Code: "VNM"
	},
	{
		name: { fr: "Yémen", en: "Yemen", original: "YEMEN" },
		ffaCode: "YEM",
		code: "YE",
		area: "ASI",
		idWindev: 246,
		flag: "https://flagpedia.net/data/flags/small/ye.png",
		alpha3Code: "YEM"
	},
	{
		name: { fr: "Zambie", en: "Zambia", original: "ZAMBIA" },
		ffaCode: "ZMB",
		code: "ZM",
		area: "AFR",
		idWindev: 247,
		flag: "https://flagpedia.net/data/flags/small/zm.png",
		alpha3Code: "ZMB"
	},
	{
		name: { fr: "Zimbabwe", en: "Zimbabwe", original: "ZIMBABWE" },
		ffaCode: "ZWE",
		code: "ZW",
		area: "AFR",
		idWindev: 248,
		flag: "https://flagpedia.net/data/flags/small/zw.png",
		alpha3Code: "ZWE"
	}
];

import { Dispatch, SetStateAction, useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { deleteComment, getComments, sendComment } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";
import Modal from "../common/Modal";
import React from "react";

const CommentsHistory = ({
	idInscription,
	setOpenedHistory,
	subscriptionRefetch,
	receiver,
	bib
}: {
	idInscription: number;
	setOpenedHistory: Dispatch<SetStateAction<boolean>>;
	subscriptionRefetch: Function;
	receiver: string;
	bib: string;
}) => {
	const { lang } = useContext(AppContext);
	const { user, userIsAdmin } = useContext(UserContext);
	const { slug } = useParams();
	const [openTextArea, setOpenTextArea] = useState(false);
	const [toggleTextArea, setToggleTextArea] = useState(false);
	const [comment, setComment] = useState("");
	const [openedDeleteModal, setOpenedDeleteModal] = useState<boolean>(false);
	const [index, setIndex] = useState<number>(0);

	const { data, refetch } = useQuery({
		queryKey: ["commentaire", slug, idInscription],
		queryFn: () => getComments(slug as string, idInscription),
		refetchOnWindowFocus: false
	});

	const userName = (user?.firstname as string) + " " + user?.lastname;

	const closeHistory = () => setOpenedHistory(false);

	const addComments = async () => {
		try {
			await sendComment(slug as string, idInscription, {
				message: comment,
				sentAt: new Date(),
				user: userName
			});
			await refetch();
			await subscriptionRefetch();
			setOpenTextArea(false);
			setToggleTextArea(!toggleTextArea);
		} catch (error) {
			console.error(error);
		}
	};

	const onClickTrash = async (index: number) => {
		await deleteComment(slug as string, idInscription, index);
		await refetch();
		await subscriptionRefetch();
	};

	const viewTextArea = () => {
		setOpenTextArea(true);
		setToggleTextArea(!toggleTextArea);
	};

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="max-w-screen-lg rounded-md border bg-white">
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h3 className="text-xl font-semibold text-gray-900">
						<span>Commentaires</span> {" - "}
						<span>{receiver}</span>
						{"  "}
						<span>{bib}</span>
					</h3>

					<button
						type="button"
						className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={closeHistory}
					>
						<AiOutlineClose size={16} />
					</button>
				</div>

				<div className="flex h-[500px] w-[350px] flex-col items-center gap-3 overflow-x-auto overflow-y-auto px-4 py-6 sm:min-w-[800px] sm:overflow-x-hidden">
					{data?.length > 0 && (
						<div className="w-full overflow-x-auto border border-gray-200">
							<table className="w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="select-none py-3.5 px-4 text-start align-top text-sm font-normal text-gray-500"
										>
											<span>Expéditeur</span>
										</th>
										<th
											scope="col"
											className="select-none py-3.5 px-4 text-start align-top text-sm font-normal text-gray-500"
										>
											<span>Date</span>
										</th>
										<th
											scope="col"
											className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
										>
											<span>Commentaires</span>
										</th>
										<th />
									</tr>
								</thead>

								<tbody className="max-w-[350px] divide-y divide-gray-200 bg-white sm:max-h-[300px] sm:min-w-[800px]">
									{data?.map((item: any, index: number) => (
										<tr key={index}>
											<td className="p-2 pt-4 text-start align-top">
												{item.user}
											</td>
											<td className="p-2 pt-4 text-start align-top">
												{item?.sentAt &&
													new Date(item?.sentAt).toLocaleDateString("fr-FR", {
														day: "numeric",
														month: "numeric",
														year: "numeric"
													})}
											</td>
											<td className="p-2 pt-4 text-start align-top">
												{item?.message
													?.split("\n")
													.map((line: any, index: any) => (
														<React.Fragment key={index}>
															{line}
															<br />
														</React.Fragment>
													))}
											</td>
											<td className="p-2 pt-4 text-start align-top">
												<FaRegTrashAlt
													size={20}
													style={{ color: "red", cursor: "pointer" }}
													onClick={() => {
														setOpenedDeleteModal(true);
														setIndex(index);
													}}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}

					{!toggleTextArea && (
						<button
							type="button"
							className="flex flex-row items-center gap-2 rounded-lg border border-gray-200 bg-white px-2.5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
							onClick={() => viewTextArea()}
						>
							<IoMdAdd size={16} />
							<span>Ajouter un commentaire</span>
						</button>
					)}

					{openTextArea && toggleTextArea && (
						<div className="overflow-y-[50%] flex flex-col gap-4">
							<textarea
								id="story"
								name="story"
								rows={5}
								placeholder="Écrire ici"
								className="w-[300px] rounded-lg border border-gray-200 sm:min-w-[780px]"
								onChange={(e) => {
									setComment(e.target.value);
								}}
								autoFocus
							/>
							<div className="flex gap-4">
								<button
									type="button"
									className="w-full rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
									onClick={addComments}
								>
									Ajouter
								</button>
								<button
									type="button"
									className="flex w-full items-center justify-center gap-4 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
									onClick={() => viewTextArea()}
								>
									<span>Annuler</span>
								</button>
							</div>
						</div>
					)}
				</div>
				{userIsAdmin && (
					<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
						<button
							type="button"
							className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
							onClick={closeHistory}
						>
							{trad[lang].close}
						</button>
					</div>
				)}
				{openedDeleteModal && (
					<Modal
						deleteBtn
						onClickButton={() => {
							onClickTrash(index);
							setOpenedDeleteModal(false);
						}}
						titleButton="supprimer"
						cancelBtn
						titleCancelButton="Annuler"
						children={
							<span>Êtes vous sûr de vouloir supprimer ce commentaire ?</span>
						}
						onClickCancelButton={() => setOpenedDeleteModal(false)}
						closeModal={() => setOpenedDeleteModal(false)}
						style={{
							width: "40%",
							height: "0",
							justifyContent: "space-around"
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default CommentsHistory;

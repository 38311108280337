import { useContext, useState } from "react";
import { To, useNavigate, useParams } from "react-router-dom";
import CreateRunnerModal from "../components/ManageRunners/CreateRunnerModal";
import EditRunnerModal from "../components/ManageRunners/EditRunnerModal";
import HandleDetectionModal from "../components/ManageRunners/HandleDetectionModal";
import ManageRunnerHeader from "../components/ManageRunners/ManageRunnerHeader";
import ManageRunnerMenu from "../components/ManageRunners/ManageRunnerMenu";
import ManageRunnerPagination from "../components/ManageRunners/ManageRunnerPagination";
import ManageRunnerReset from "../components/ManageRunners/ManageRunnerReset";
import ManageRunnerResults from "../components/ManageRunners/ManageRunnerResults";
import ManageRunnerResum from "../components/ManageRunners/ManageRunnerResum";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import { ManageRunnerContext } from "../contexts/ManageRunnerContext";
import trad from "../lang/traduction";

const ManageRunners = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const {
		LiveConfig,
		setOpenedSubscriptionWithoutId,
		openedSubscriptionWithoutId
	} = useContext(ManageRunnerContext);
	const [openResum, setOpenResum] = useState(false);
	const [runnerData, setRunnerData] = useState(null);
	const [detectionsData, setDetectionsData] = useState([]);

	const handleRunnerClick = (data: any, detectedData: any) => {
		setRunnerData(data);
		setDetectionsData(detectedData);
		setOpenResum(true);
	};
	const navigate = useNavigate();
	const pathKeys = [
		{
			id: 1,
			path: `/${slug}/live-details`,
			title: "Configuration"
		},
		{
			id: 2,
			path: `/${slug}/manage-detections`,
			title: "Détections"
		}
	];

	const navigate_to = (path: string) => navigate(path as To);
	return (
		<Layout
			active_key="live"
			forceReduced={true}
			bottomBar={
				<div className="flex h-[56px] w-full items-center border-t border-t-slate-400 bg-white">
					<div className="ml-4 flex items-center justify-center gap-3">
						{pathKeys.map((item: any) => {
							return (
								<div key={item.id} className="flex gap-3">
									<button
										className="flex h-full items-center gap-1 rounded-md border border-primary bg-white py-3 px-3 text-xs text-primary duration-150 hover:bg-primarymedium hover:text-white md:uppercase"
										onClick={() => navigate_to(item.path)}
									>
										{item.title}
									</button>
								</div>
							);
						})}
					</div>
					{LiveConfig?.prestation && <ManageRunnerPagination />}
				</div>
			}
			full
		>
			<div className="flex h-full flex-col">
				<ManageRunnerMenu />

				{LiveConfig?.prestation ? (
					<div className="overflow-x-auto border border-slate-400">
						<div className="w-full min-w-[1200px]">
							{/* HEADER */}
							<ManageRunnerHeader />

							<ManageRunnerResults onRunnerClick={handleRunnerClick} />
						</div>
					</div>
				) : (
					<div className="flex p-5 text-center">
						<h2 className="w-full">{trad[lang].noPrestationStarted}</h2>
					</div>
				)}
			</div>

			<EditRunnerModal />
			<HandleDetectionModal />

			{openResum ? (
				<ManageRunnerResum
					data={runnerData}
					closeModal={() => setOpenResum(false)}
					detectionsData={detectionsData}
				/>
			) : null}

			<ManageRunnerReset />

			{openedSubscriptionWithoutId && (
				<CreateRunnerModal
					onClose={() => setOpenedSubscriptionWithoutId(false)}
				/>
			)}
		</Layout>
	);
};

export default ManageRunners;

import { Dispatch, SetStateAction, useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

const ValidationReason = ({
	validationReason,
	setValidationReason,
	handleStatusChange,
	setOpenedValidation,
	loading
}: {
	validationReason: string;
	setValidationReason: Dispatch<SetStateAction<string>>;
	setOpenedValidation: Dispatch<SetStateAction<boolean>>;
	handleStatusChange: Function;
	loading: boolean;
}) => {
	const { lang } = useContext(AppContext);

	const closeChangeStatus = () => setOpenedValidation(false);

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="max-h-[90vh] max-w-screen-lg rounded-md border bg-white">
				<div className="mb-6 flex items-start justify-between rounded-t border-b p-4">
					<h3 className="text-xl font-semibold text-gray-900">
						{trad[lang].refuse_subscription}
					</h3>

					<button
						type="button"
						className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={closeChangeStatus}
						disabled={loading}
					>
						<AiOutlineClose size={16} />
						<span className="sr-only">Close modal</span>
					</button>
				</div>

				<div className="px-4 pb-6">
					<h4 className="pb-4">{trad[lang].refuse_reason}</h4>

					<textarea
						className="m-0 min-h-[150px] w-full appearance-none rounded-lg border border-gray-200 bg-white md:w-[500px]"
						onChange={(e) => setValidationReason(e.target.value)}
						value={validationReason}
						disabled={loading}
					/>
				</div>

				<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
					<button
						type="button"
						className="rounded-lg  bg-primary px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300"
						onClick={() => handleStatusChange("2")}
						disabled={loading}
					>
						{trad[lang].refuse_subscription}
					</button>

					<button
						type="button"
						className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
						onClick={closeChangeStatus}
						disabled={loading}
					>
						{trad[lang].close_documents}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ValidationReason;
